import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const transitionEndEventName = () => {
    var el = document.createElement('div');
    var transEndEventNames = {
        WebkitTransition : 'webkitTransitionEnd',
        MozTransition    : 'transitionend',
        OTransition      : 'oTransitionEnd otransitionend',
        transition       : 'transitionend'
    }

    for (var name in transEndEventNames) {
        if (el.style[name] !== undefined) {
            return transEndEventNames[name];
        }
    }

    return false; // explicit for ie8 (  ._.)
}


class Cover extends Component {
    constructor(props) {
        super(props);

        this.transitionEndEventName = transitionEndEventName();

        this.refStart = React.createRef();
        this.refEnd = React.createRef();

        this.transitionEndEvent = this.transitionEndEvent.bind(this);
    }

    componentDidMount() {
        this.refStart.current.addEventListener(this.transitionEndEventName, this.transitionEndEvent, false);
        this.refEnd.current.addEventListener(this.transitionEndEventName, this.transitionEndEvent, false);
    }

    componentWillUnmount() {
        this.refStart.current.removeEventListener(this.transitionEndEventName, this.transitionEndEvent, false);
        this.refEnd.current.removeEventListener(this.transitionEndEventName, this.transitionEndEvent, false);
    }

    transitionEndEvent(e) {
        if (e.target.classList.contains('enter') || e.target.classList.contains('leave')) {
            this.props.router.switch.progress();
        }
    }

    render() {
        const { router: { switch: { active, step } } } = this.props;
        const classStart = cx('page-cover', 'page-cover-start', {
            enter: active && step === 0,
            leave: active && step === 1,
        });
        const classEnd = cx('page-cover', 'page-cover-end', {
            enter: active && step === 2,
            leave: active && step === 3,
        });
        const classLogo = cx('page-cover', 'page-cover-logo', {
            enter: active && step === 0,
            stay: active && (step === 1 || step === 2),
            leave: active && step === 3,
        });

        return (
            <Fragment>
                <div className={ classStart } ref={ this.refStart }/>
                <div className={ classLogo }>
                    <img
                        src={ require('assets/cover.jpg') }
                        srcSet={ `${ require('assets/cover.jpg') } 1x, ${ require('assets/cover@2x.jpg') } 2x` }
                        alt="Dahlia"
                    />
                </div>
                <div className={ classEnd } ref={ this.refEnd }/>
            </Fragment>
        );
    }
}

Cover.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Cover;
