import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from 'components/elements/Input';

const FormStep6 = ({ active, data, errors, inputChange }) => (
    <div className={ cx('checklist-form-step', { active }) }>
        <Input
            scope="checklist"
            field="additional_info_entertainment"
            label="Entertainment (Instagram or Name) *"
            value={ data.additional_info_entertainment }
            error={ errors.additional_info_entertainment }
            inputChange={ inputChange('additional_info_entertainment') }
        />
        <Input
            scope="checklist"
            field="additional_info_photographer"
            label="Photographer (Instagram or Name) *"
            value={ data.additional_info_photographer }
            error={ errors.additional_info_photographer }
            inputChange={ inputChange('additional_info_photographer') }
        />
        <Input
            scope="checklist"
            field="additional_info_video"
            label="Video (Instagram or Name)"
            value={ data.additional_info_video }
            error={ errors.additional_info_video }
            inputChange={ inputChange('additional_info_video') }
        />
        <Input
            scope="checklist"
            field="additional_info_boutique"
            label="Boutique (Instagram or Name)"
            value={ data.additional_info_boutique }
            error={ errors.additional_info_boutique }
            inputChange={ inputChange('additional_info_boutique') }
        />
        <Input
            scope="checklist"
            field="additional_info_dress"
            label="Dress (Instagram or Name)"
            value={ data.additional_info_dress }
            error={ errors.additional_info_dress }
            inputChange={ inputChange('additional_info_dress') }
        />
        <Input
            scope="checklist"
            field="additional_info_tux"
            label="Tux (Instagram or Name)"
            value={ data.additional_info_tux }
            error={ errors.additional_info_tux }
            inputChange={ inputChange('additional_info_tux') }
        />
        <Input
            scope="checklist"
            field="additional_info_mekup"
            label="Mekup (Instagram or Name)"
            value={ data.additional_info_mekup }
            error={ errors.additional_info_mekup }
            inputChange={ inputChange('additional_info_mekup') }
        />
        <Input
            scope="checklist"
            field="additional_info_hair"
            label="Hair (Instagram or Name)"
            value={ data.additional_info_hair }
            error={ errors.additional_info_hair }
            inputChange={ inputChange('additional_info_hair') }
        />
        <Input
            scope="checklist"
            field="additional_info_limo"
            label="Limo (Instagram or Name)"
            value={ data.additional_info_limo }
            error={ errors.additional_info_limo }
            inputChange={ inputChange('additional_info_limo') }
        />
        <div className="checklist-form-step-required">* Required Fields</div>
    </div>
);

FormStep6.propTypes = {
    active: PropTypes.bool.isRequired,
    data: PropTypes.shape().isRequired,
    inputChange: PropTypes.func.isRequired
};

export default FormStep6;
