import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Video extends Component {
    constructor(props) {
        super(props);

        this.videoBox = React.createRef();
        this.videoRef = React.createRef();
        this.containerRef = React.createRef();

        this.state = {
            open: false,
            x: 0,
            y: 0,
            videoPercent: 0
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        this.videoBox.current.addEventListener('mouseenter', this.onMouseEnter);
        this.videoBox.current.addEventListener('mousemove', this.onMouseMove);
        this.videoBox.current.addEventListener('mouseleave', this.onMouseLeave);

        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        this.videoBox.current.removeEventListener('mouseenter', this.onMouseEnter);
        this.videoBox.current.removeEventListener('mousemove', this.onMouseMove);
        this.videoBox.current.removeEventListener('mouseleave', this.onMouseLeave);

        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onMouseEnter() {
        this.setState({ open: true });
    }

    onMouseLeave() {
        this.setState({ open: false });
    }

    onMouseMove(e) {
        const rect = this.videoBox.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        this.setState({ x, y });
    }

    onScroll() {
        if (!this.videoRef || !this.containerRef) {
            return;
        }

        const windowHeight = window.innerHeight;
        const containerHeight = this.containerRef.current.getBoundingClientRect().height;
        const containerTop = this.containerRef.current.getBoundingClientRect().top;

        const startTop = 0;
        const endTop = -1 * (containerHeight - windowHeight);

        let videoPercent = 0;
        if (containerTop < startTop && containerTop > endTop) {
            videoPercent = containerTop / endTop;
        } else if (containerTop <= endTop) {
            videoPercent = 1;
        }

        this.setState({ videoPercent });
    }


    render() {
        const { videoFixedOpen, router: { breakpoint } } = this.props;
        const { videoPercent } = this.state;

        let playCss = {};
        if (breakpoint === 'widescreen' || breakpoint === 'desktop' || breakpoint === 'laptop') {
            playCss.transform = `translate(${ this.state.x }px, ${ this.state.y }px)`;
        } else if (breakpoint === 'tablet-hor') {
            playCss.left = `${ (25 + (25 * videoPercent)) }%`;
        }

        const videoCSS = {
            filter: `grayscale(${ 100 * (1 - videoPercent) }%)`
        };

        return (
            <div className="container-home-video" ref={ this.containerRef }>
                <div className="page-content">
                    <div className="page-home-video">
                        <div className="home-video-box" onClick={ videoFixedOpen } ref={ this.videoBox }>
                            <video
                                className="home-video"
                                src={ require('assets/home/video.mp4') }
                                poster={ require('assets/home/video.jpg') }
                                playsInline
                                autoPlay
                                muted
                                loop
                                ref={ this.videoRef }
                                style={ videoCSS }
                            />
                            <div className={ cx('home-video-play', { open: this.state.open }) } style={ playCss }>
                                <span>Play</span>
                            </div>
                        </div>
                        <div className="home-video-content">
                            <div className="home-video-text">
                                Flowers<br/>
                                are happy<br/>
                                things
                            </div>
                            <div className="home-video-content-play" onClick={ videoFixedOpen }>Play Movie</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Video.propTypes = {
    videoFixedOpen: PropTypes.func.isRequired,
    router: PropTypes.shape().isRequired
};

export default Video;
