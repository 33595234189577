import React from 'react';
import PropTypes from 'prop-types';

const Intro = ({ title, description }) => (
    <div className="page-content">
        <div className="page-intro">
            <h1 className="intro-title" dangerouslySetInnerHTML={ { __html: title } }/>
            <p className="intro-desc" dangerouslySetInnerHTML={ { __html: description } }/>
        </div>
    </div>
);

Intro.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Intro;
