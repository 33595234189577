import React, { Component } from 'react';
import PropTypes from 'prop-types';

import routes from '../config/routes';

import App from './App';

const parsePathname = (pathname) => {
    const fullPathname = pathname;
    const basename = process.env.REACT_APP_BASE_URL;
    const appPathname = basename ? fullPathname.replace(basename, '') : fullPathname;
    const pathParts = appPathname.split('/').slice(1);

    const page = (Object.values(routes).indexOf(pathParts[0]) !== -1) ? pathParts[0] : '';
    let subpage = null;
    if (page === routes.ROUTE_GALLERY) {
        subpage = typeof pathParts[1] !== 'undefined' && pathParts[1] ? pathParts[1] : null;
    }
    return {
        page,
        subpage
    };
};

class Router extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: '',
            subpage: null,
            switchActive: false,
            switchStep: 0,
            switchUrl: null,
            menuActive: false,
            breakpoint: 'widescreen'
        };

        this.url = this.url.bind(this);
        this.urlHash = this.urlHash.bind(this);

        this.push = this.push.bind(this);
        this.historyListen = this.historyListen.bind(this);

        this.switchStart = this.switchStart.bind(this);
        this.switchEnd = this.switchEnd.bind(this);
        this.switchProgress = this.switchProgress.bind(this);

        this.menuOpen = this.menuOpen.bind(this);
        this.menuClose = this.menuClose.bind(this);

        this.historyUnlisten = null;

        this.breakpointRefresh = this.breakpointRefresh.bind(this);
    }

    componentWillMount() {
        const { history } = this.props;
        this.historyListen(history.location);
        this.historyUnlisten = history.listen(this.historyListen);
        this.breakpointRefresh();
    }

    componentDidMount() {
        window.addEventListener('resize', this.breakpointRefresh);
    }

    componentWillUnmount() {
        this.historyUnlisten();
        window.removeEventListener('resize', this.breakpointRefresh);
    }

    breakpointRefresh() {
        const breakpoint = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/"|'/g, '');
        this.setState({ breakpoint });
    }

    url(page = null, subpage = null, hash = null) {
        const { location: { search } } = this.props.history;

        const p = page ? `${ page }/` : '';
        const sp = subpage ? `${ subpage }/` : '';
        const h = hash ? `#${ hash }` : '';
        const url = `${ process.env.REACT_APP_BASE_URL }/${ p }${ sp }${ search }${ h }`;

        return url;
    }

    urlCurrent() {
        return this.url(this.state.page, this.state.subpage, this.props.history.location.hash.replace('#', ''));
    }

    urlHash(hash) {
        return this.url(this.state.page, this.state.subpage, hash);
    }

    push(url, shouldScroll = true) {
        if (shouldScroll) {
            window.scrollTo(0, 0);
        }
        this.switchStart(url);
    }

    historyListen(location) { // @param action - POP / PUSH / REPLACE
        this.setState(parsePathname(location.pathname));
    }

    switchStart(url) {
        this.setState({
            switchActive: true,
            switchStep: 0,
            switchUrl: url
        });
    }

    switchEnd() {
        this.setState({
            switchActive: false,
            switchStep: 0,
            switchUrl: null
        });
    }

    switchProgress() {
        const switchStep = this.state.switchStep + 1;
        if (switchStep === 1) {
            this.menuClose();
        }
        if (switchStep === 3) {
            this.props.history.push(this.state.switchUrl);
        }

        if (switchStep < 4) {
            this.setState({ switchStep });
        } else {
            this.switchEnd();
        }
    }

    menuOpen() {
        this.setState({
            menuActive: true
        });
    }

    menuClose() {
        this.setState({
            menuActive: false
        });
    }

    render() {
        const {
            page,
            subpage,
            breakpoint
        } = this.state;

        const router = {
            page,
            subpage,
            breakpoint,
            hash: this.props.history.location.hash.replace('#', ''),
            push: this.push,
            url: {
                general: this.url,
                home: () => this.url(),
                about: () => this.url(routes.ROUTE_ABOUT),
                checklist: () => this.url(routes.ROUTE_CHECKLIST),
                contact: () => this.url(routes.ROUTE_CONTACT),
                gallery: () => this.url(routes.ROUTE_GALLERY),
                gallerySingle: (slug) => this.url(routes.ROUTE_GALLERY, slug),
                current: this.urlCurrent,
                hash: this.urlHash
            },
            is: {
                home: page === '',
                about: page === routes.ROUTE_ABOUT,
                checklist: page === routes.ROUTE_CHECKLIST,
                contact: page === routes.ROUTE_CONTACT,
                gallery: page === routes.ROUTE_GALLERY
            },
            switch: {
                active: this.state.switchActive,
                step: this.state.switchStep,
                progress: this.switchProgress
            },
            menu: {
                open: this.menuOpen,
                close: this.menuClose,
                active: this.state.menuActive
            }
        };

        return (
            <App router={ router }/>
        );
    }
}

Router.propTypes = {
    history: PropTypes.shape().isRequired
};

export default Router;
