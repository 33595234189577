import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/helpers/Link';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
            open: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const windowHeight = window.innerHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        this.setState({
            fixed: scrollTop > 100,
            open: scrollTop > windowHeight
        });
    }

    render() {
        const { router } = this.props;
        const { fixed, open } = this.state;

        return (
            <div className={ cx('container-header', { fixed, open }) }>
                <div className="page-header">
                    <Link router={ router } href={ router.url.home() } className="page-logo">
                        { (!fixed && router.breakpoint !== 'phone') && <img src={ require('assets/logo-header.svg') } alt="Dahlia"/> }
                        { (!fixed && router.breakpoint === 'phone') && <img src={ require('assets/logo-header-mobile.svg') } alt="Dahlia"/> }
                        { fixed && <img src={ require('assets/logo-small.svg') } alt="Dahlia"/> }
                    </Link>
                    <div className="menu-trigger" onClick={ router.menu.open }>
                        <span className="line line-1"/>
                        <span className="line line-2"/>
                        <span className="line line-3"/>
                    </div>
                    <Link router={ router } href={ router.url.checklist() } className="header-book">
                        <span className="header-book-inside">Book a Consultation</span>
                    </Link>
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Header;
