import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class ImageBox extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            open: false
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        if (!this.ref || this.state.open) {
            return;
        }

        const windowHeight = window.innerHeight;
        const elemTop = this.ref.current.getBoundingClientRect().top;

        if (elemTop < windowHeight / 2) {
            this.setState({ open: true });
            document.removeEventListener('scroll', this.onScroll);
            document.removeEventListener('resize', this.onScroll);
        }
    }

    render() {
        const { src, src2x } = this.props;

        return (
            <span className={ cx('gallery-image-box', { open: this.state.open }) } ref={ this.ref }>
                <img
                    src={ src }
                    srcSet={ `${ src } 1x, ${ src2x } 2x` }
                    alt=""
                />
            </span>
        );
    }
}

ImageBox.propTypes = {
    src: PropTypes.string.isRequired,
    src2x: PropTypes.string.isRequired
};

export default ImageBox;
