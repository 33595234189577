import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SliderGallery from 'components/elements/SliderGallery';

class Slides extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slide: null,
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const { router: { breakpoint } } = this.props;
        if (breakpoint === 'phone' || breakpoint === 'tablet-ver') {
            this.setState({ slide: null });
            return;
        }

        const windowHeight = window.innerHeight;
        const images = document.querySelectorAll('.home-slides-images img');
        let slide = null;
        for (let i = 0; i < images.length; ++i) {
            const imageTop = images[i].getBoundingClientRect().top;
            if (imageTop < windowHeight / 2) {
                slide = i;
            }
        }
        this.setState({ slide });
    }

    render() {
        const { router: { breakpoint } } = this.props;

        return (
            <div className="container-home-slides">
                <div className="page-content">
                    <div className="page-home-slides">
                        <div className="home-slides-text">
                            <div className="home-slides-text-inside">
                                <h2>Breathtaking Floral Decor to Adorn<br/> the Day of Your Dreams</h2>
                                <div className="paragraphs">
                                    <p className={ cx({ active: this.state.slide === 0 }) }>By working with only the freshest, most exquisite flowers that boast a ‘just-picked’ feel, we create spectacular floral and event decor that will drape your special day in charm and elegance.</p>
                                    <p className={ cx({ active: this.state.slide === 1 }) }>Simple and modern. Dramatic and extravagant. Romantic and airy. Whichever style suits your fancy, our team at Dahlia will mix our creativity with your vision to make your day feel like a fairytale.</p>
                                    <p className={ cx({ active: this.state.slide === 2 }) }>Never settle for less than extravagant on the day you’ll remember for the rest of your life. We adore details; from bloming floral decor, to romantic candle-light, to magnificent centerpieces and more, we will set an unforgettable scene for you to say ‘I Do.’ </p>
                                </div>
                            </div>
                        </div>
                        {
                            !(breakpoint === 'phone' || breakpoint === 'tablet-ver') && (
                                <div className="home-slides-images">
                                    {
                                        window.globalHomeSlidesDesktop.map(({ src, src2x }, index) => (
                                            <img
                                                key={ index }
                                                src={ src }
                                                srcSet={ `${ src } 1x, ${ src2x } 2x` }
                                                alt=""
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            (breakpoint === 'phone' || breakpoint === 'tablet-ver') && (
                                <SliderGallery images={ window.globalHomeSlidesMobile } className="slider-gallery-home-slides"/>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

Slides.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Slides;
