import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Cover from './elements/Cover';
import Footer from './elements/Footer';
import Header from './elements/Header';
import Menu from './elements/Menu';
import SideMenu from './elements/SideMenu';
import Page from './Page';

class App extends Component {
    render() {
        const { router } = this.props;

        return (
            <Fragment>
                <Cover router={ router }/>
                <Header router={ router }/>
                <SideMenu router={ router }/>
                <Menu router={ router }/>
                <div className="page-container">
                    <Page router={ router }/>
                </div>
                <Footer router={ router }/>
            </Fragment>
        );
    }
}

App.propTypes = {
    router: PropTypes.shape().isRequired
};

export default App;
