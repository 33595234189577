import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FormProgress = ({ step }) => (
    <div className={ cx('checklist-form-progress', `checklist-form-progress-${ step }` ) }/>
);

FormProgress.propTypes = {
    step: PropTypes.number.isRequired
};

export default FormProgress;
