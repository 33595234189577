import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/helpers/Link';

class Awards extends Component {
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();
        this.awardsRef = React.createRef();

        this.state = {
            contentOpen: false,
            awardsOpen: false
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const windowHeight = window.innerHeight;
        const contentTop = this.contentRef.current.getBoundingClientRect().top;
        const awardsTop = this.awardsRef.current.getBoundingClientRect().top;
        const awardsHeight = this.awardsRef.current.getBoundingClientRect().height;

        if (contentTop < windowHeight / 2) {
            this.setState({ contentOpen: true }, () => {
                if (this.state.contentOpen && this.state.awardsOpen) {
                    document.removeEventListener('scroll', this.onScroll);
                    document.removeEventListener('resize', this.onScroll);
                }
            });
        }

        let awardsStartTop = windowHeight / 2;
        if (this.props.router.breakpoint === 'tablet-hor') {
            awardsStartTop = windowHeight - awardsHeight * 2;
        }

        if (awardsTop < awardsStartTop) {
            this.setState({ awardsOpen: true }, () => {
                if (this.state.contentOpen && this.state.awardsOpen) {
                    document.removeEventListener('scroll', this.onScroll);
                    document.removeEventListener('resize', this.onScroll);
                }
            });
        }
    }

    render() {
        const { router } = this.props;
        const excerpt = 'A team of visionaries that welcomes new challenges and aspires to exceed all expectations.'.split(' ');

        let delay = 0;
        const calcDelayStyle = () => {
            const style = {
                transitionDelay: `${ delay }s`
            };
            delay += 0.1;
            return style;
        }

        return (
            <div className="container-home-awards">
                <div className="page-content">
                    <div className="page-home-awards">
                        <div className={ cx('home-awards-content', { open: this.state.contentOpen }) } ref={ this.contentRef }>
                            <h3>
                                <span className="reveal" style={ calcDelayStyle() }>The</span>
                                <span className="reveal" style={ calcDelayStyle() }>Dahlia</span>
                                <span className="reveal" style={ calcDelayStyle() }>Difference</span>
                            </h3>
                            <p className="excerpt">
                                {
                                    excerpt.map((word, index) => (
                                        <span className="reveal" style={ calcDelayStyle() } key={ index }>{ word } </span>
                                    ))
                                }
                            </p>
                            <p className="desc reveal2" style={ calcDelayStyle() }>
                                For many years, we worked with high-end fashion clients such as Louis Vuitton and Cartier, designing and bringing to life themed floral arrangements for special, high-end events. This unique experience has developed our team’s sharp eye for detail, and enables us to infuse each arrangement with passion and creativity.
                            </p>
                            <Link router={ router } href={ router.url.about() } className="link reveal2" style={ calcDelayStyle() }>
                                See Dahlia in action
                            </Link>
                        </div>
                        <div className={ cx('home-awards-list', { open: this.state.awardsOpen }) } ref={ this.awardsRef }>
                            <div className="home-award">
                                <div className="home-award-logo">
                                    <img
                                        src={ require('assets/home/awards-the-knot.svg') }
                                        alt="The Knot"
                                    />
                                </div>
                                <div className="home-awards-reviews">
                                    <span>5.0 out of 5.0 /</span>
                                    <a
                                        href="https://www.theknot.com/marketplace/dahlia-floral-and-event-design-garfield-nj-588424"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        151 reviews
                                    </a>
                                </div>
                                <div className="home-award-text">
                                    Couple’s Choice Award:<br/>
                                    2015, 2016, 2017, 2018, 2019
                                </div>
                            </div>
                            <div className="home-award">
                                <div className="home-award-logo">
                                    <img
                                        src={ require('assets/home/awards-wedding-wire.svg') }
                                        alt="Wedding Wire"
                                    />
                                </div>
                                <div className="home-awards-reviews">
                                    <span>5.0 out of 5.0 /</span>
                                    <a
                                        href="https://www.weddingwire.com/reviews/dahlia-floral-event-design-garfield/2c9a6066509e39b8.html"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        ‎179 reviews
                                    </a>
                                </div>
                                <div className="home-award-text">
                                    The Best Of Weddings:<br/>
                                    2015, 2016, 2017, 2018, 2019<br/>
                                    The Knot Hall Of Fame
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Awards.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Awards;
