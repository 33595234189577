import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

const VideoFixed = ({ isOpen, closeClick, youtubeCode }) => {
    const template = (
        <div className={ cx('home-video-fixed', { open: isOpen }) }>
            <div className="home-video-fixed-content">
                <div className="home-video-fixed-close" onClick={ closeClick }></div>
                <div className="home-video-fixed-wrapper">
                    { isOpen && <iframe
                        src={ `https://www.youtube.com/embed/${ youtubeCode }` }
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Video"
                    /> }
                </div>
            </div>
        </div>
    )

    return ReactDOM.createPortal(
        template,
        document.body
    );
};

VideoFixed.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeClick: PropTypes.func.isRequired,
    youtubeCode: PropTypes.string.isRequired
};

export default VideoFixed;
