import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/helpers/Link';

class ListItem extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            active: false,
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const windowHeight = window.innerHeight;
        const elemBox = this.ref.current.getBoundingClientRect();
        const active = elemBox.top < windowHeight - elemBox.height;

        this.setState({ active });
    }

    render() {
        const { router, gallery, index } = this.props;

        return (
            <div className={ cx('gallery-elem', `gallery-elem-${ index }`, { active: this.state.active }) } ref={ this.ref }>
                <Link
                    router={ router }
                    href={ router.url.gallerySingle(gallery.slug) }
                >
                    <img
                        src={ gallery.list_image }
                        srcSet={ `${ gallery.list_image } 1x, ${ gallery.list_image_2x } 2x` }
                        alt={ gallery.title }
                        className="gallery-elem-image"
                    />
                    <div className="gallery-elem-overlay"></div>
                    <h3 className="gallery-elem-title">{ gallery.title }</h3>
                </Link>
            </div>
        );
    }
}

ListItem.propTypes = {
    router: PropTypes.shape().isRequired,
    gallery: PropTypes.shape().isRequired,
};

export default ListItem;