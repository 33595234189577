import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ router }) => (
    <div className="container-footer">
        <div className="page-footer">
            <div className="footer-logo">
                <img src={ require('assets/logo-footer.svg') } alt="Dahlia"/>
            </div>
            <div className="footer-address">
                <div className="footer-address-column footer-address-details">
                    <p className="paragraph-address">
                        <a href="https://goo.gl/maps/8d9N3FMXhsK2" target="_blank" rel="noopener noreferrer">
                            5 Lanza Avenue<br/>
                            Garfield, New Jersey 07026
                        </a>
                    </p>
                    <p className="paragraph-hours">
                        Tue – Sat / 9am – 5pm<br/>
                        Tue – Thur / late consultation night<br/>
                        Sun – Mon / closed
                    </p>
                </div>
                <div className="footer-address-column footer-address-contact">
                    <p className="paragraph-email">
                        Email<br/>
                        <a href="mailto:info@dahliaflorals.com">info@dahliaflorals.com</a>
                    </p>
                    <p className="paragraph-phone">
                        Telephone<br/>
                        <a href="tel:+19737720219">+1  973. 772. 0219</a>
                    </p>
                </div>
            </div>
            <div className="footer-ratings">
                <div className="footer-rating">
                    <span className="footer-rating-title">WeddingWire</span>
                    <span className="footer-rating-text">
                        <span className="rating"><span className="label">Rating: </span>5.0 out of 5.0</span>
                        <span className="slash">/</span>
                        <a
                            href="https://www.weddingwire.com/reviews/dahlia-floral-event-design-garfield/2c9a6066509e39b8.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                        >
                            ‎179 reviews
                        </a>
                    </span>
                    <a
                        href="https://www.weddingwire.com/reviews/dahlia-floral-event-design-garfield/2c9a6066509e39b8.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-rating-icon"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM15,5a2.19,2.19,0,1,1-2.19,2.19A2.19,2.19,0,0,1,15,5Zm1.78,6.79A1.78,1.78,0,1,1,15,10,1.78,1.78,0,0,1,16.73,11.78Zm3.8,0h-.27a20.08,20.08,0,0,0-4.81,13.45h-.91A20.08,20.08,0,0,0,9.73,11.78H9.46a2.19,2.19,0,1,1,2.19-2.19,2,2,0,0,1-.91,1.78,19.55,19.55,0,0,1,3.8,7.23V14.4h.91v4.27a20.46,20.46,0,0,1,3.83-7.26,2.33,2.33,0,0,1-.91-1.78,2.17,2.17,0,1,1,2.16,2.15Z"/></svg>
                    </a>
                </div>
                <div className="footer-rating">
                    <span className="footer-rating-title">The Knot</span>
                    <span className="footer-rating-text">
                        <span className="rating"><span className="label">Rating: </span>5.0 out of 5.0</span>
                        <span className="slash">/</span>
                        <a
                            href="https://www.theknot.com/marketplace/dahlia-floral-and-event-design-garfield-nj-588424"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                        >
                            151 reviews
                        </a>
                    </span>
                    <a
                        href="https://www.theknot.com/marketplace/dahlia-floral-and-event-design-garfield-nj-588424"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-rating-icon"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm5.36,20.92a5.42,5.42,0,0,1-2.22,2.36,2.6,2.6,0,0,1-1,.23,3.48,3.48,0,0,1-1.42-.18,3.43,3.43,0,0,1-1.26-.82,9.79,9.79,0,0,1-1.09-1.42L13.26,21l-.08-.12c-.11.8-.26,2.63-.25,3.07,0,.17-.11.3-.35.37a1.82,1.82,0,0,1-.46.06,3,3,0,0,1-.55-.06.38.38,0,0,1-.33-.46c0-.29.33-2.79.44-4.26,0-.54.11-1.24.17-1.8l.08-.8-.06.08-.13.18-.47.65a.38.38,0,0,1-.51.15,1.46,1.46,0,0,1-.68-.53.42.42,0,0,1,0-.48,3.69,3.69,0,0,1,.4-.55l.12-.15c.29-.35.56-.72.81-1.06a3.27,3.27,0,0,0,.48-.87,6.39,6.39,0,0,0,.3-1.53c.05-.44.09-1,.13-1.45s.08-.92.11-1.23l0-.23a23.12,23.12,0,0,1,.43-2.66A4.14,4.14,0,0,1,14,5.11a1.49,1.49,0,0,1,1.31-.32,1,1,0,0,1,.7.52c.88,1.33-.12,4.45-.45,5.38a29.19,29.19,0,0,1-1.8,3.79l-.11.2c-.05.32-.08.68-.11,1l-.06.61c0,.19-.06.89-.05,1.17a16.7,16.7,0,0,0,2.14-1.33,9,9,0,0,0,1.71-1.52l0,0a3.15,3.15,0,0,0,.31-.41.42.42,0,0,1,.25-.21.46.46,0,0,1,.36.08,1.34,1.34,0,0,1,.62.89.46.46,0,0,1-.15.45,14.59,14.59,0,0,1-2.25,2,14.29,14.29,0,0,1-1.9,1.12c-.24.12-.45.23-.63.34l0,0-.1,0,0,.09.21.38.33.6a5.78,5.78,0,0,0,1.06,1.41,2.27,2.27,0,0,0,.89.51,1.71,1.71,0,0,0,.73.05,1.39,1.39,0,0,0,.68-.32,7.75,7.75,0,0,0,1.52-2.05.37.37,0,0,1,.52-.14A1.23,1.23,0,0,1,20.36,20.92ZM14.88,8.57a4.33,4.33,0,0,0,.24-2.4l0,0h0a.3.3,0,0,0-.16,0,.56.56,0,0,0-.14.18,8.22,8.22,0,0,0-.61,2.23c-.14.8-.3,2.41-.35,3.19a17.74,17.74,0,0,0,1-2.74C14.8,8.89,14.84,8.73,14.88,8.57Z"/></svg>
                    </a>
                </div>
                <div className="footer-rating">
                    <span className="footer-rating-title">Instagram</span>
                    <a href="https://www.instagram.com/dahliaflorals/" className="footer-rating-text" target="_blank" rel="noopener noreferrer">
                        10,000+ followers
                    </a>
                    <a
                        href="https://www.instagram.com/dahliaflorals/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-rating-icon"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M20.09,11.15a2.18,2.18,0,0,0-1.24-1.24,3.43,3.43,0,0,0-1.21-.22c-.69,0-.9,0-2.64,0s-1.95,0-2.64,0a3.9,3.9,0,0,0-1.21.22,2.18,2.18,0,0,0-1.24,1.24,3.43,3.43,0,0,0-.22,1.21c0,.69,0,.9,0,2.64s0,1.95,0,2.64a3.9,3.9,0,0,0,.22,1.21,2.18,2.18,0,0,0,1.24,1.24,3.43,3.43,0,0,0,1.21.22c.69,0,.89,0,2.64,0s1.95,0,2.64,0a3.9,3.9,0,0,0,1.21-.22,2.18,2.18,0,0,0,1.24-1.24,3.43,3.43,0,0,0,.22-1.21c0-.69,0-.89,0-2.64s0-1.95,0-2.64A3.68,3.68,0,0,0,20.09,11.15ZM15,18.35A3.35,3.35,0,1,1,18.35,15,3.35,3.35,0,0,1,15,18.35Zm3.48-6.05a.78.78,0,1,1,.78-.78A.78.78,0,0,1,18.48,12.3ZM15,12.83A2.17,2.17,0,1,1,12.83,15,2.17,2.17,0,0,1,15,12.83ZM15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm6.49,17.69a4.76,4.76,0,0,1-.3,1.58,3.34,3.34,0,0,1-.75,1.16,3,3,0,0,1-1.16.75,4.81,4.81,0,0,1-1.58.3c-.7,0-.92,0-2.69,0s-2,0-2.69,0a4.76,4.76,0,0,1-1.58-.3,3.34,3.34,0,0,1-1.16-.75,3,3,0,0,1-.75-1.16,4.81,4.81,0,0,1-.3-1.58c0-.7,0-.92,0-2.69s0-2,0-2.69a4.76,4.76,0,0,1,.3-1.58,3.34,3.34,0,0,1,.75-1.16,3,3,0,0,1,1.16-.75,4.81,4.81,0,0,1,1.58-.3c.7,0,.92,0,2.69,0s2,0,2.69,0a4.76,4.76,0,0,1,1.58.3,3.34,3.34,0,0,1,1.16.75,3,3,0,0,1,.75,1.16,4.81,4.81,0,0,1,.3,1.58c0,.7,0,.92,0,2.69S21.52,17,21.49,17.69Z"/></svg>
                    </a>
                </div>
            </div>
            <div className="footer-copyright">
                &copy; 2019 Dahlia Floral & Event Design.<br/>
                All Rights Reserved. Design & Code: <a href="https://www.takticstudio.com/" target="_blank" rel="noopener noreferrer">Taktic Studio</a>
            </div>
        </div>
    </div>
);

Footer.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Footer;
