import React from 'react';
import PropTypes from 'prop-types';

const Top = ({ router, gallery }) => (
    <div className="container-gallery-top">
        <div className="page-content">
            <div className="page-gallery-top">
                {
                    router.subpage ? (
                        router.breakpoint === 'phone' ? (
                            <img
                                src={ gallery.cover_mobile }
                                alt={ gallery.title }
                                className="gallery-top-image"
                            />
                        ) : (
                            <img
                                src={ gallery.cover_desktop }
                                alt={ gallery.title }
                                className="gallery-top-image"
                            />
                        )
                    ) : (
                        router.breakpoint === 'phone' ? (
                            <img
                                src={ require(`assets/gallery/list-cover-mobile.jpg`) }
                                alt="Gallery"
                                className="gallery-top-image"
                            />
                        ) : (
                            <img
                                src={ require(`assets/gallery/list-cover.jpg`) }
                                alt="Gallery"
                                className="gallery-top-image"
                            />
                        )
                    )
                }
                <div className="gallery-top-content">
                    <div
                        className="gallery-top-text"
                        dangerouslySetInnerHTML={ { __html: (router.subpage ? gallery.slogan : 'You Bring the Love,<br/> We’ll Bring the Magic') } }
                    />
                </div>
            </div>
        </div>
    </div>
);

Top.defaultProps = {
    gallery: null
};

Top.propTypes = {
    router: PropTypes.shape().isRequired,
    gallery: PropTypes.shape()
};

export default Top;
