import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from 'components/elements/Input';

const FormStep1 = ({ active, data, errors, inputChange }) => (
    <div className={ cx('checklist-form-step', { active }) }>
        <Input
            scope="checklist"
            field="name"
            label="Name *"
            value={ data.name }
            error={ errors.name }
            inputChange={ inputChange('name') }
        />
        <Input
            type="date"
            scope="checklist"
            field="date_of_event"
            label="Date of Event *"
            value={ data.date_of_event }
            error={ errors.date_of_event }
            inputChange={ inputChange('date_of_event', 'date') }
        />
        <Input
            scope="checklist"
            field="bride_name"
            label="Bride's Name *"
            value={ data.bride_name }
            error={ errors.bride_name }
            inputChange={ inputChange('bride_name') }
        />
        <Input
            scope="checklist"
            field="groom_name"
            label="Grooms's Name *"
            value={ data.groom_name }
            error={ errors.groom_name }
            inputChange={ inputChange('groom_name') }
        />
        <Input
            scope="checklist"
            field="event_planner_name"
            label="Event Planner's Name"
            value={ data.event_planner_name }
            error={ errors.event_planner_name }
            inputChange={ inputChange('event_planner_name') }
        />
        <Input
            scope="checklist"
            field="event_planner_phone"
            label="Event Planner’s Phone #"
            value={ data.event_planner_phone }
            error={ errors.event_planner_phone }
            inputChange={ inputChange('event_planner_phone') }
        />
        <div className="checklist-form-step-required">* Required Fields</div>
    </div>
);

FormStep1.propTypes = {
    active: PropTypes.bool.isRequired,
    data: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    inputChange: PropTypes.func.isRequired
};

export default FormStep1;
