import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class FooterQuote extends Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();

        this.state = {
            open: false,
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const windowHeight = window.innerHeight;

        if (!this.state.open) {
            const elemTop = this.containerRef.current.getBoundingClientRect().top;

            if (elemTop < windowHeight / 2) {
                this.setState({ open: true });
            }
        }
    }

    render() {
        const { type = 'home' } = this.props;
        const quote = typeof window.globalFooterQuotes[type] !== 'undefined' ? window.globalFooterQuotes[type] : null;

        let delay = 0;
        const calcDelayStyle = () => {
            const style = {
                transitionDelay: `${ delay }s`
            };
            delay += 0.1;
            return style;
        }

        return (
            <div className="page-content" ref={ this.containerRef }>
                <div className={ cx('footer-quote', { open: this.state.open }) }>
                    <blockquote className="footer-quote-box">
                        <p className="footer-quote-text">
                            {
                                quote && quote.text.map((line, index) => (
                                    <Fragment key={ index }>
                                        { index === 0 ? null : <br/> }
                                        {
                                            line.split(' ').map((word, wordIndex) => (
                                                <span className="reveal" style={ calcDelayStyle() } key={ wordIndex }>{ word } </span>
                                            ))
                                        }
                                    </Fragment>
                                ))
                            }
                        </p>
                        <span className="footer-quote-author">
                            {
                                quote && quote.author.split(' ').map((word, wordIndex) => (
                                    <span className="reveal" style={ calcDelayStyle() } key={ wordIndex }>{ word } </span>
                                ))
                            }
                        </span>
                    </blockquote>
                </div>
            </div>
        );
    }
}

FooterQuote.propTypes = {
    type: PropTypes.string
};

export default FooterQuote;
