import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const steps = [
    'Contact information',
    'Delivery for Bride',
    'Delivery for Groom',
    'Ceremony',
    'Venue',
    'Additional Info'
];

const FormNav = ({ step }) => (
    <ul className="checklist-form-nav">
        {
            steps.map((title, index) => (
                <li
                    className={ cx('checklist-form-nav-item', { current: (step === index + 1) }) }
                    key={ index }
                >
                    <span
                        className={ cx('checklist-form-nav-link', { active: (step > index) }) }
                    >
                        { title }
                    </span>
                </li>
            ))
        }
    </ul>
);

FormNav.propTypes = {
    step: PropTypes.number.isRequired
};

export default FormNav;
