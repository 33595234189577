import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import SliderGallery from 'components/elements/SliderGallery';
import Intro from 'components/elements/Intro';
import FooterQuote from 'components/elements/FooterQuote';
import VideoFixed from './home/VideoFixed';

import Team from './about/Team';

class About extends Component {
    constructor(props) {
        super(props);

        this.videoRef = React.createRef();

        this.state = {
            videoFixedIsOpen: false,
            videoPercent: 0,
            videoHeight: 0
        };

        this.videoFixedOpen = this.videoFixedOpen.bind(this);
        this.videoFixedClose = this.videoFixedClose.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    videoFixedOpen() {
        this.setState({ videoFixedIsOpen: true });
    }

    videoFixedClose() {
        this.setState({ videoFixedIsOpen: false });
    }

    onScroll() {
        const windowHeight = window.innerHeight;
        const videoTop = this.videoRef.current.getBoundingClientRect().top;
        const videoHeight = this.videoRef.current.getBoundingClientRect().height;

        const startTop = windowHeight - videoHeight / 2;
        const endTop = 0;

        let videoPercent = 1;
        if (videoTop < startTop && videoTop >= endTop) {
            videoPercent = videoTop / startTop;
        } else if (videoTop < endTop) {
            videoPercent = 0;
        }

        this.setState({ videoPercent, videoHeight });
    }

    render() {
        const { videoHeight, videoPercent } = this.state;

        const line1Percent = videoPercent > 0.5 ? (videoPercent - 0.5) * 2 : 0;
        const line2Percent = videoPercent < 0.5 ? videoPercent * 2 : 1;

        const line1Css = {
            transform: `translate(0, ${ -1 * videoHeight * line1Percent }px)`
        };
        const line2Css = {
            transform: `translate(0, ${ -1 * videoHeight * line2Percent }px)`
        };

        return (
            <Fragment>
                <Intro
                    title="About Dahlia Floral &amp;<br/> Event Design"
                    description="Our floral arrangements exceed the ordinary, adding a particular touch of magic to your wedding day."
                />
                <div className="container-about">
                    <div className="page-content">
                        <div className="about-dark">
                            <p className="about-text">
                                Flowers are our passion, and we’re lucky enough to devote our lives to creating beautiful floral works of art. From years of designing and creating dramatic, elevated floral decor for high-end fashion clients and celebrities, we have a sharp eye for detail and an incredible ability to implement creativity and passion into every floral decor that we create.
                            </p>
                            <div className="about-video" ref={ this.videoRef }>
                                <h4 className="about-video-title">
                                    <span className="line-1" style={ line1Css }>Every detail</span>
                                    <span className="line-2" style={ line2Css }>matters</span>
                                </h4>
                                <div className="about-video-play" onClick={ this.videoFixedOpen }/>
                            </div>
                            <p className="about-text">
                                We work with you throughout every step of the process. During your consultation, we’ll take-in your ideas, using examples and options that will bring your vision to life within your allotted budget. We’re hands on throughout the entire process, right down to safely hand-delivering every item to your event space. We’re a family-run business, and we handle each event with honesty, professionalism, and kindness, because we truly care — and we’d be so honored to be a part of your special day.
                            </p>
                            <SliderGallery images={ window.globalAboutSliderGallery }/>
                        </div>
                    </div>
                </div>
                <VideoFixed isOpen={ this.state.videoFixedIsOpen } closeClick={ this.videoFixedClose } youtubeCode="TlHGL0vLLBE"/>
                <Intro
                    title="Dahlia’s Team"
                    description="Our wonderful team is prepared to help you with even the most challenging floral project for any occasion. Our mission is to not only meet all expectations of our valued clients but to exceed them. Your satisfaction is our personal success."
                />
                <Team/>
                <FooterQuote type="about"/>
            </Fragment>
        );
    }
}

About.propTypes = {
    router: PropTypes.shape().isRequired
};

export default About;
