import React from 'react';
import PropTypes from 'prop-types';

import routes from '../config/routes';

import Home from './pages/Home';
import About from './pages/About';
import Checklist from './pages/Checklist';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';

const pageComponents = {
    home: Home,
    [routes.ROUTE_ABOUT]: About,
    [routes.ROUTE_CHECKLIST]: Checklist,
    [routes.ROUTE_CONTACT]: Contact,
    [routes.ROUTE_GALLERY]: Gallery
};

const Page = ({ router }) => {
    const Component = router.page === '' ? pageComponents.home : pageComponents[router.page];

    return (
        <Component router={ router }/>
    );
};

Page.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Page;
