import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from 'components/elements/Input';

const FormStep2 = ({ active, data, errors, inputChange }) => (
    <div className={ cx('checklist-form-step', { active }) }>
        <Input
            scope="checklist"
            field="bride_address"
            label="Address (Street, City, State and Zip Code) *"
            value={ data.bride_address }
            error={ errors.bride_address }
            inputChange={ inputChange('bride_address') }
        />
        <Input
            scope="checklist"
            field="bride_phone"
            label="Phone number *"
            value={ data.bride_phone }
            error={ errors.bride_phone }
            inputChange={ inputChange('bride_phone') }
        />
        <Input
            type="time"
            scope="checklist"
            field="bride_time"
            label="Time *"
            value={ data.bride_time }
            error={ errors.bride_time }
            inputChange={ inputChange('bride_time', 'time') }
        />
        <Input
            scope="checklist"
            field="bride_emergency_phone"
            label="Emergency phone number *"
            value={ data.bride_emergency_phone }
            error={ errors.bride_emergency_phone }
            inputChange={ inputChange('bride_emergency_phone') }
        />
        <div className="checklist-form-step-required">* Required Fields</div>
    </div>
);

FormStep2.propTypes = {
    active: PropTypes.bool.isRequired,
    data: PropTypes.shape().isRequired,
    inputChange: PropTypes.func.isRequired
};

export default FormStep2;
