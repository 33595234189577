import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import FooterQuote from 'components/elements/FooterQuote';

import List from './gallery/List';
import Single from './gallery/Single';

const Gallery = ({ router }) => (
    <Fragment>
        { !router.subpage && <List router={ router }/> }
        { router.subpage && <Single router={ router }/> }
        <FooterQuote type={ router.subpage ? `gallery-${ router.subpage }` : 'gallery' }/>
    </Fragment>
);

Gallery.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Gallery;
