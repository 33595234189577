import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/helpers/Link';

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            galleryExpanded: false,
            image: null
        };

        this.toogleGallery = this.toogleGallery.bind(this);
        this.setImage = this.setImage.bind(this);
    }

    componentDidMount() {
        const { router } = this.props;
        const image = router.page === 'gallery' && router.subpage ? `${ router.page }-${ router.subpage }` : router.page;
        this.setState({ image });
    }

    toogleGallery() {
        this.setState({ galleryExpanded: !this.state.galleryExpanded });
    }

    setImage(image) {
        return (e) => {
            e.stopPropagation();
            this.setState({ image });
        };
    }

    render() {
        const { router } = this.props;

        return (
            <div className={ cx('menu-container', { open: router.menu.active }) }>
                <div className="menu-close" onClick={ router.menu.close }>
                    <span className="line line-1"/>
                    <span className="line line-2"/>
                </div>
                <nav className="menu-content">
                    <div className="menu-content-logo">
                        { router.breakpoint !== 'phone' && <img src={ require('assets/logo-header-white.svg') } alt="Dahlia"/> }
                        { router.breakpoint === 'phone' && <img src={ require('assets/logo-header-white-mobile.svg') } alt="Dahlia"/> }
                    </div>
                    <ul className="menu-items">
                        <li className="menu-item" onMouseOver={ this.setImage('home') }>
                            <Link router={ router } href={ router.url.home() } className="menu-item-link">
                                Home
                            </Link>
                        </li>
                        <li className="menu-item" onMouseOver={ this.setImage('about') }>
                            <Link router={ router } href={ router.url.about() } className="menu-item-link">
                                About Us
                            </Link>
                        </li>
                        <li className={ cx('menu-item', { open: this.state.galleryExpanded }) } onMouseOver={ this.setImage('gallery') }>
                            <Link router={ router } href={ router.url.gallery() } className="menu-item-link">
                                Gallery
                            </Link>
                            <span className="menu-item-submenu-trigger" onClick={ this.toogleGallery }/>
                            <ul className={ cx('menu-item-submenu', `menu-item-submenu-${ window.globalGalleries.length }`) }>
                                {
                                    window.globalGalleries.map(({ title, slug }) => (
                                        <li className="menu-item-submenu-item" key={ slug } onMouseOver={ this.setImage(`gallery-${ slug }`) }>
                                            <Link router={ router } href={ router.url.gallerySingle(slug) } className="menu-item-submenu-link">
                                                { title }
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                        <li className="menu-item" onMouseOver={ this.setImage('contact') }>
                            <Link router={ router } href={ router.url.contact() } className="menu-item-link">
                                Contact
                            </Link>
                        </li>
                        <li className="menu-item" onMouseOver={ this.setImage('checklist') }>
                            <Link router={ router } href={ router.url.checklist() } className="menu-item-link">
                                Final Checklist
                            </Link>
                        </li>
                        <li className="menu-item" onMouseOver={ this.setImage('appointments') }>
                            <a href={ `${ process.env.REACT_APP_BASE_URL }/appointments/` } className="menu-item-link">
                                Appointments
                            </a>
                        </li>
                        <li className="menu-item" onMouseOver={ this.setImage('payments') }>
                            <a href="https://www.paypal.me/DahliaFlorals" rel="noopener noreferrer" target="_blank" className="menu-item-link">
                                Payments
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="menu-images">
                    <div className="menu-images-logo">
                        <img src={ require('assets/logo-header.svg') } alt="Dahlia"/>
                    </div>
                    {
                        window.globalMenuImages.map(({ image, src, src2x}) => (
                            <img
                                key={ image }
                                src={ src }
                                srcSet={ `${ src } 1x, ${ src2x } 2x` }
                                className={ cx('menu-images-image', { active: (image === this.state.image) }) }
                                alt=""
                            />
                        ))
                    }
                </div>
            </div>
        );
    }
}

Menu.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Menu;
