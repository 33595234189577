import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import FooterQuote from 'components/elements/FooterQuote';

import Video from './home/Video';
import VideoFixed from './home/VideoFixed';
import Slides from './home/Slides';
import Weddings from './home/Weddings';
import Awards from './home/Awards';
import Testimonials from './home/Testimonials';
import Events from './home/Events';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoFixedIsOpen: false
        };

        this.videoFixedOpen = this.videoFixedOpen.bind(this);
        this.videoFixedClose = this.videoFixedClose.bind(this);
    }

    videoFixedOpen() {
        this.setState({ videoFixedIsOpen: true });
    }

    videoFixedClose() {
        this.setState({ videoFixedIsOpen: false });
    }

    render() {
        const { router } = this.props;

        return (
            <Fragment>
                <Video videoFixedOpen={ this.videoFixedOpen } router={ router }/>
                <VideoFixed isOpen={ this.state.videoFixedIsOpen } closeClick={ this.videoFixedClose } youtubeCode="_asMgE7bpUU"/>
                <Slides router={ router }/>
                <Weddings router={ router }/>
                <Awards router={ router }/>
                <Testimonials router={ router }/>
                <Events router={ router }/>
                <FooterQuote type="home"/>
            </Fragment>
        );
    }
}

Home.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Home;
