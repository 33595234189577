import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/helpers/Link';

class Weddings extends Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.contentRef = React.createRef();
        this.imageRef = React.createRef();

        this.state = {
            contentShow: 0,
            imagePercent: 0
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const { router: { breakpoint } } = this.props;

        const windowHeight = window.innerHeight;

        let contentShow = false;
        if (breakpoint === 'phone' || breakpoint === 'tablet-ver') {
            contentShow = true;
        } else {
            const containerTop = this.containerRef.current.getBoundingClientRect().top;
            const contentHeight = this.contentRef.current.getBoundingClientRect().height;

            const constentStartTop = windowHeight - contentHeight / 2;

            if (containerTop <= constentStartTop) {
                contentShow = true
            }
        }

        const imageBox = this.imageRef.current.getBoundingClientRect();
        const imageTop = imageBox.top;
        const imageHeight = imageBox.height;

        let imageStartTop = windowHeight - imageHeight * 0.4;
        let imageEndTop = windowHeight - imageHeight * 1.4;
        if (breakpoint === 'tablet-ver') {
            imageStartTop = windowHeight - imageHeight * 0.8;
            imageEndTop = windowHeight - imageHeight * 1.8;
        } else if (breakpoint === 'phone') {
            imageStartTop = windowHeight - imageHeight * 1;
            imageEndTop = windowHeight - imageHeight * 2.2;
        }

        let imagePercent = 1;
        if (imageTop <= imageStartTop && imageTop >= imageEndTop) {
            imagePercent = (imageTop - imageEndTop) / imageHeight;
        } else if (imageTop < imageEndTop) {
            imagePercent = 0;
        }

        this.setState({ contentShow, imagePercent });
    }

    render() {
        const { router } = this.props;

        const colorImageCss = {
            height: `${ 100 * (1 - this.state.imagePercent) }%`
        };

        return (
            <div className="container-home-weddings" ref={ this.containerRef }>
                <div className="page-content">
                    <div className="page-home-weddings">
                        <div className={ cx('home-weddings-content', { open: this.state.contentShow }) } ref={ this.contentRef }>
                            <h3>A Day to Remember</h3>
                            <p>Floral Decor You’ll Never Forget. We don’t simply ‘arrange flowers.’As an artist paints each stroke with thought, care, and precision onto the canvas, we place each flower the same way, creating stunning floral works of art that elevate the romance and ambiance at your wedding.</p>
                        </div>
                        <div className="home-weddings-image">
                            <img
                                src={ require('assets/home/weddings.jpg') }
                                srcSet={ `${ require('assets/home/weddings.jpg') } 1x, ${ require('assets/home/weddings@2x.jpg') } 2x` }
                                alt=""
                                ref={ this.imageRef }
                                className="image-gray"
                            />
                            <div className="image-color-box" style={ colorImageCss }>
                                <img
                                    src={ require('assets/home/weddings.jpg') }
                                    srcSet={ `${ require('assets/home/weddings.jpg') } 1x, ${ require('assets/home/weddings@2x.jpg') } 2x` }
                                    alt=""
                                    className="image-color"
                                />
                            </div>
                            <div className="home-weddings-button">
                                <Link router={ router } href={ router.url.gallery() } className="button">
                                    <span className="button-inside">View Gallery</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Weddings.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Weddings;
