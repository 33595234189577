import React from 'react';
import PropTypes from 'prop-types';

const FormButtons = ({ step, prevStep, nextStep, formSubmit, disabled }) => (
    <div className="checklist-form-buttons">
        {
            (step > 1) && (
                <button
                    type="button"
                    className="button button-80 button-back"
                    onClick={ prevStep }
                >
                    <span className="button-inside">Go Back</span>
                </button>
            )
        }
        {
            (step < 6) && (
                <button
                    type="button"
                    className="button button-80"
                    onClick={ nextStep }
                >
                    <span className="button-inside">Next Step</span>
                </button>
            )
        }
        {
            (step === 6) && (
                <button
                    type="submit"
                    className="button button-80"
                    onClick={ formSubmit }
                    disabled={ disabled }
                >
                    <span className="button-inside">Submit</span>
                </button>
            )
        }
    </div>
);

FormButtons.propTypes = {
    step: PropTypes.number.isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    formSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default FormButtons;
