import React, { Component } from 'react';

class Team extends Component {
    constructor(props) {
        super(props);

        this.state = {
            percent: 0
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const container = document.querySelector('.about-team');
        const elem = document.querySelector('.about-team-slider');
        if (container === null || elem === null) {
            return;
        }

        const containerBox = container.getBoundingClientRect();
        const containerTop = containerBox.top;
        const containerHeight = containerBox.height;

        const elemBox = elem.getBoundingClientRect();
        const elemHeight = elemBox.height;
        const elemStyle = elem.currentStyle || window.getComputedStyle(elem);
        const elemTop = parseInt(elemStyle.top, 10);
        if (isNaN(elemTop) && elemTop <= 0) {
            return;
        }

        const stickyStart = elemTop;
        const stickyEnd = elemTop + elemHeight - containerHeight;

        let percent = 0;
        if (containerTop > stickyStart) {
            // before sticky
            percent = 0;
        } else if (containerTop <= stickyStart && containerTop >= stickyEnd) {
            // stuck
            percent = 1.0 * (containerTop - stickyStart) / (stickyEnd - stickyStart);
        } else if (containerTop < stickyEnd) {
            // after sticky
            percent = 1;
        }

        this.setState({ percent });
    }

    render() {
        const { percent } = this.state;

        const cssPercent = (100 - (100 / window.globalAboutTeamGallery.length)) * percent;
        const cssStyle = {
            transform: `translate(-${ cssPercent }%, 0)`
        };

        return (
            <div className="page-content">
                <div className="about-team">
                    <div className="about-team-slider">
                        <div className="about-team-slides" style={ cssStyle }>
                            {
                                window.globalAboutTeamGallery.map(({ src, src2x }, index) => (
                                    <img
                                        src={ src }
                                        srcSet={ `${ src } 1x, ${ src2x } 2x` }
                                        alt=""
                                        key={ index }
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Team;
