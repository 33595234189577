import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Top from './Top';

import ListItem from './ListItem';

const List = ({ router }) => (
    <Fragment>
        <Top router={ router }/>
        <div className="container-gallery">
            <div className="page-content">
                <div className="page-gallery-list">
                    {
                        window.globalGalleries.map((gallery, index) => (
                            <ListItem
                                key={ gallery.slug }
                                gallery={ gallery }
                                router={ router }
                                index={ index }
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    </Fragment>
);

List.propTypes = {
    router: PropTypes.shape().isRequired
};

export default List;