import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// import TimePicker from 'react-times';

const formatDate = (d) => {
    if (d instanceof Date && !isNaN(d.valueOf())) {
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        return `${ month }/${ day }/${ year }`;
    }
    return '';
}

const parseDate = (str) => {
    if (typeof str !== 'string') {
        return undefined;
    }
    const split = str.split('-');
    if (split.length !== 3) {
        return undefined;
    }
    const year = parseInt(split[2], 10);
    const month = parseInt(split[1], 10) - 1;
    const day = parseInt(split[0], 10);
    if (
        isNaN(year) ||
        String(year).length > 4 ||
        isNaN(month) ||
        isNaN(day) ||
        day <= 0 ||
        day > 31 ||
        month < 0 ||
        month >= 12
    ) {
      return undefined;
    }

    return new Date(year, month, day);
}

class Input extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false
        }

        this.inputFocus = this.inputFocus.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    inputFocus() {
        this.setState({ focus: true });
    }

    inputBlur() {
        this.setState({ focus: false });
    }

    render() {
        const { type, scope, field, label, value, inputChange, selectValues, error } = this.props;
        // , 'not-empty-time': (value !== '12:00 AM')
        return (
            <div className={ cx('element-input', { focus: this.state.focus, 'not-empty': (value !== ''), error }) }>
                <div className="element-input-error">This field is required</div>
                <label
                    htmlFor={ `${ scope }-${ field }` }
                    className="element-input-label"
                >
                    { label }
                </label>
                {
                    (type === 'text' || type === 'email' || type === 'tel') && (
                        <input
                            type={ type }
                            className="element-input-text"
                            name={ field }
                            id={ `${ scope }-${ field }` }
                            value={ value }
                            onChange={ inputChange }
                            onFocus={ this.inputFocus }
                            onBlur={ this.inputBlur }
                        />
                    )
                }
                {
                    (type === 'select') && (
                        <select
                            className="element-input-select"
                            name={ field }
                            id={ `${ scope }-${ field }` }
                            value={ value }
                            onChange={ inputChange }
                            onFocus={ this.inputFocus }
                            onBlur={ this.inputBlur }
                        >
                            <option value=""></option>
                            {
                                selectValues.map(({ value: v, title }) => (
                                    <option key={ v } value={ v }>{ title }</option>
                                ))
                            }
                        </select>
                    )
                }
                {
                    (type === 'textarea') && (
                        <textarea
                            className="element-input-textarea"
                            name={ field }
                            id={ `${ scope }-${ field }` }
                            value={ value }
                            onChange={ inputChange }
                            onFocus={ this.inputFocus }
                            onBlur={ this.inputBlur }
                        />
                    )
                }
                {
                    (type === 'date') && (
                        <DayPickerInput
                            placeholder={ null }
                            value={ value }
                            formatDate={ formatDate }
                            parseDate={ parseDate }
                            inputProps={ {
                                className: 'element-input-text',
                                name: field,
                                id: `${ scope }-${ field }`,
                                readOnly: true,
                                onFocus: this.inputFocus,
                                onBlur: this.inputBlur
                            } }
                            onDayChange={ inputChange }
                        />
                    )
                }
                {
                    (type === 'time') && (
                        <select
                            className="element-input-select"
                            name={ field }
                            id={ `${ scope }-${ field }` }
                            value={ value }
                            onChange={ inputChange }
                            onFocus={ this.inputFocus }
                            onBlur={ this.inputBlur }
                        >
                            <option value=""></option>
                            <option value="12:00 AM">12:00 AM</option>
                            <option value="00:30 AM">00:30 AM</option>
                            <option value="01:00 AM">01:00 AM</option>
                            <option value="01:30 AM">01:30 AM</option>
                            <option value="02:00 AM">02:00 AM</option>
                            <option value="02:30 AM">02:30 AM</option>
                            <option value="03:00 AM">03:00 AM</option>
                            <option value="03:30 AM">03:30 AM</option>
                            <option value="04:00 AM">04:00 AM</option>
                            <option value="04:30 AM">04:30 AM</option>
                            <option value="05:00 AM">05:00 AM</option>
                            <option value="05:30 AM">05:30 AM</option>
                            <option value="06:00 AM">06:00 AM</option>
                            <option value="06:30 AM">06:30 AM</option>
                            <option value="07:00 AM">07:00 AM</option>
                            <option value="07:30 AM">07:30 AM</option>
                            <option value="08:00 AM">08:00 AM</option>
                            <option value="08:30 AM">08:30 AM</option>
                            <option value="09:00 AM">09:00 AM</option>
                            <option value="09:30 AM">09:30 AM</option>
                            <option value="10:00 AM">10:00 AM</option>
                            <option value="10:30 AM">10:30 AM</option>
                            <option value="11:00 AM">11:00 AM</option>
                            <option value="11:30 AM">11:30 AM</option>
                            <option value="12:00 PM">12:00 PM</option>
                            <option value="00:30 PM">00:30 PM</option>
                            <option value="01:00 PM">01:00 PM</option>
                            <option value="01:30 PM">01:30 PM</option>
                            <option value="02:00 PM">02:00 PM</option>
                            <option value="02:30 PM">02:30 PM</option>
                            <option value="03:00 PM">03:00 PM</option>
                            <option value="03:30 PM">03:30 PM</option>
                            <option value="04:00 PM">04:00 PM</option>
                            <option value="04:30 PM">04:30 PM</option>
                            <option value="05:00 PM">05:00 PM</option>
                            <option value="05:30 PM">05:30 PM</option>
                            <option value="06:00 PM">06:00 PM</option>
                            <option value="06:30 PM">06:30 PM</option>
                            <option value="07:00 PM">07:00 PM</option>
                            <option value="07:30 PM">07:30 PM</option>
                            <option value="08:00 PM">08:00 PM</option>
                            <option value="08:30 PM">08:30 PM</option>
                            <option value="09:00 PM">09:00 PM</option>
                            <option value="09:30 PM">09:30 PM</option>
                            <option value="10:00 PM">10:00 PM</option>
                            <option value="10:30 PM">10:30 PM</option>
                            <option value="11:00 PM">11:00 PM</option>
                            <option value="11:30 PM">11:30 PM</option>
                        </select>
                    )
                }
                {
                    /* (type === 'time') && (
                        <div className="element-input-time">
                            <TimePicker
                                withoutIcon
                                time={ value }
                                timeMode={ 12 }
                                onTimeChange={ inputChange }
                            />
                        </div>
                    ) */
                }
            </div>
        );
    }
}

Input.defaultProps = {
    type: 'text',
    value: '',
    selectValues: [],
    error: false
};

Input.propTypes = {
    type: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    error: PropTypes.bool,
    inputChange: PropTypes.func.isRequired,
    selectValues: PropTypes.array,
};

export default Input;

