import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ImageBox from './ImageBox';

const Row = ({ type, images }) => (
    <div className={ cx('gallery-row', `gallery-row-${ type }`) }>
        {
            images.map(({ src, src2x }, index) => (
                <div className="gallery-image" key={ index }>
                    <ImageBox src={ src } src2x={ src2x }/>
                </div>
            ))
        }
    </div>
);

Row.propTypes = {
    type: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired
};

export default Row;
