import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import throttle from 'lodash/throttle';

import Link from '../helpers/Link';

class Side extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hide: false
        };

        this.onScroll = this.onScroll.bind(this);
        this.onScrollThrottle = throttle(this.onScroll, 50);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScrollThrottle);
        document.addEventListener('resize', this.onScrollThrottle);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollThrottle);
        document.removeEventListener('resize', this.onScrollThrottle);
    }

    onScroll() {
        const pageContainer = document.querySelector('.page-container');
        if (pageContainer === null) {
            return;
        }
        const style = pageContainer.currentStyle || window.getComputedStyle(pageContainer);
        const marginBottom = parseInt(style.marginBottom, 10);
        if (isNaN(marginBottom) || marginBottom <= 0) {
            return;
        }

        const doc = document.documentElement;
        const scrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        const windowHeight = window.innerHeight;
        const documentHeight = doc.scrollHeight;

        if (scrollTop + windowHeight + marginBottom > documentHeight) {
            this.setState({ hide: true });
        } else {
            this.setState({ hide: false });
        }
    }

    render() {
        const { hide } = this.state;
        const { router } = this.props;

        return (
            <div className={ cx('container-side-menu', { hide }) }>
                <div className="page-side-menu">
                    <ul className="side-menu">
                        <li className={ cx('side-menu-item', { active: router.is.contact }) }>
                            <Link router={ router } href={ router.url.contact() } className="side-menu-link">
                                Event Inquiry
                            </Link>
                        </li>
                        <li className={ cx('side-menu-item', { active: router.is.checklist }) }>
                            <Link router={ router } href={ router.url.checklist() } className="side-menu-link">
                                Final Checklist
                            </Link>
                        </li>
                        <li className={ cx('side-menu-item') }>
                            <a href={ `${ process.env.REACT_APP_BASE_URL }/appointments/` } className="side-menu-link">
                                Appointments
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
};

Side.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Side;
