const ROUTE_ABOUT = 'about';
const ROUTE_CHECKLIST = 'checklist';
const ROUTE_CONTACT = 'contact';
const ROUTE_GALLERY = 'gallery';

export default {
    ROUTE_ABOUT,
    ROUTE_CHECKLIST,
    ROUTE_CONTACT,
    ROUTE_GALLERY
};
