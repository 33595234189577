import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Top from './Top';
import Row from './Row';

const Single = ({ router }) => {
    const gallery = window.globalGalleries.find((item) => item.slug === router.subpage);
    if (typeof gallery === 'undefined' || !gallery) {
        return null;
    }

    return (
        <Fragment>
            <Top router={ router } gallery={ gallery }/>
            <div className="container-gallery">
                <div className="page-content">
                    <div className="page-gallery">
                        {
                            gallery.rows.map(({ type, images }, index) => (
                                <Row images={ images } type={ type } key={ index }/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

Single.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Single;
