import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Link from 'components/helpers/Link';
import Intro from 'components/elements/Intro';
import FooterQuote from 'components/elements/FooterQuote';

import FormNav from './checklist/FormNav';
import FormProgress from './checklist/FormProgress';
import FormStep1 from './checklist/FormStep1';
import FormStep2 from './checklist/FormStep2';
import FormStep3 from './checklist/FormStep3';
import FormStep4 from './checklist/FormStep4';
import FormStep5 from './checklist/FormStep5';
import FormStep6 from './checklist/FormStep6';
import FormButtons from './checklist/FormButtons';

const formatDate = (d) => {
    if (d instanceof Date && !isNaN(d.valueOf())) {
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        return `${ month }/${ day }/${ year }`;
    }
    return '';
}

const emptyData = {
    name: '',
    date_of_event: '',
    bride_name: '',
    groom_name: '',
    event_planner_name: '',
    event_planner_phone: '',
    bride_address: '',
    bride_phone: '',
    bride_time: '', // '12:00 AM',
    bride_emergency_phone: '',
    groom_address: '',
    groom_phone: '',
    groom_time: '', // '12:00 AM',
    groom_emergency_phone: '',
    church_name: '',
    church_address: '',
    church_contact: '',
    church_time: '', // '12:00 AM',
    venue_name: '',
    venue_address: '',
    banquet_manager: '',
    start_time: '', // '12:00 AM',
    end_time: '', // '12:00 AM',
    additional_info_entertainment: '',
    additional_info_photographer: '',
    additional_info_video: '',
    additional_info_boutique: '',
    additional_info_dress: '',
    additional_info_tux: '',
    additional_info_mekup: '',
    additional_info_hair: '',
    additional_info_limo: '',
};

const emptyErrors = {
    name: false,
    date_of_event: false,
    bride_name: false,
    groom_name: false,
    event_planner_name: false,
    event_planner_phone: false,
    bride_address: false,
    bride_phone: false,
    bride_time: false,
    bride_emergency_phone: false,
    groom_address: false,
    groom_phone: false,
    groom_time: false,
    groom_emergency_phone: false,
    church_name: false,
    church_address: false,
    church_contact: false,
    church_time: false,
    venue_name: false,
    venue_address: false,
    banquet_manager: false,
    start_time: false,
    end_time: false,
    additional_info_entertainment: false,
    additional_info_photographer: false,
    additional_info_video: false,
    additional_info_boutique: false,
    additional_info_dress: false,
    additional_info_tux: false,
    additional_info_mekup: false,
    additional_info_hair: false,
    additional_info_limo: false,
};

class Checklist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            data: {
                ...emptyData,
            },
            errors: {
                ...emptyErrors,
            },
            responseType: null,
            sending: false,
        };

        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        // this.setStep = this.setStep.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);

        this.errorAny = this.errorAny.bind(this);
    }

    errorAny() {
        return Object.keys(this.state.errors).reduce((acc, key) => acc || this.state.errors[key], false);
    }

    prevStep() {
        if (this.state.step > 1) {
            this.setState({ step: this.state.step - 1 });
        }
    }

    nextStep() {
        if (this.state.step < 7) {
            let error = false;
            const { errors } = this.state;

            switch (this.state.step) {
                case 1: {
                    errors.name = (this.state.data.name === '');
                    errors.date_of_event = (this.state.data.date_of_event === '');
                    errors.bride_name = (this.state.data.bride_name === '');
                    errors.groom_name = (this.state.data.groom_name === '');
                    error = error || errors.name || errors.date_of_event || errors.bride_name || errors.groom_name;
                    break;
                }
                case 2: {
                    errors.bride_address = (this.state.data.bride_address === '');
                    errors.bride_phone = (this.state.data.bride_phone === '');
                    errors.bride_time = (this.state.data.bride_time === '');
                    errors.bride_emergency_phone = (this.state.data.bride_emergency_phone === '');
                    error = error || errors.bride_address || errors.bride_phone || errors.bride_time || errors.bride_emergency_phone;
                    break;
                }
                case 3: {
                    errors.groom_address = (this.state.data.groom_address === '');
                    errors.groom_phone = (this.state.data.groom_phone === '');
                    errors.groom_time = (this.state.data.groom_time === '');
                    errors.groom_emergency_phone = (this.state.data.groom_emergency_phone === '');
                    error = error || errors.groom_address || errors.groom_phone || errors.groom_time || errors.groom_emergency_phone;
                    break;
                }
                case 4: {
                    errors.church_name = (this.state.data.church_name === '');
                    errors.church_address = (this.state.data.church_address === '');
                    errors.church_contact = (this.state.data.church_contact === '');
                    errors.church_time = (this.state.data.church_time === '');
                    error = error || errors.church_name || errors.church_address || errors.church_contact || errors.church_time;
                    break;
                }
                case 5: {
                    errors.venue_name = (this.state.data.venue_name === '');
                    errors.venue_address = (this.state.data.venue_address === '');
                    errors.banquet_manager = (this.state.data.banquet_manager === '');
                    errors.start_time = (this.state.data.start_time === '');
                    errors.end_time = (this.state.data.end_time === '');
                    error = error || errors.venue_name || errors.venue_address || errors.banquet_manager || errors.start_time || errors.end_time;
                    break;
                }
                case 6: {
                    errors.additional_info_entertainment = (this.state.data.additional_info_entertainment === '');
                    errors.additional_info_photographer = (this.state.data.additional_info_photographer === '');
                    errors.additional_info_video = (this.state.data.additional_info_video === '');
                    error = error || errors.additional_info_entertainment || errors.additional_info_photographer || errors.additional_info_video;
                    break;
                }
                default: {
                    break;
                }
            }
            this.setState({ errors });
            if (error === false) {
                this.setState({ step: this.state.step + 1 });
            }
        }
    }

    /* setStep(step) {
        return () => {
            if (step >= 1 && step <= 6) {
                this.setState({ step });
            }
        }
    } */

    inputChange(field, type) {
        return (e) => {
            switch (type) {
                case 'date': {
                    this.setState({
                        data: {
                            ...this.state.data,
                            [field]: e
                        }
                    });
                    break;
                }
                /* case 'time': {
                    const { hour, minute, meridiem } = e;
                    this.setState({
                        data: {
                            ...this.state.data,
                            [field]: `${ hour }:${ minute } ${ meridiem }`,
                        }
                    });
                    break;
                } */
                default: {
                    this.setState({
                        data: {
                            ...this.state.data,
                            [field]: e.target.value
                        }
                    });
                    break;
                }
            }
        }
    }

    formSubmit(e) {
        e.preventDefault();

        let error = false;
        const { errors } = this.state;
        errors.additional_info_entertainment = (this.state.data.additional_info_entertainment === '');
        errors.additional_info_photographer = (this.state.data.additional_info_photographer === '');
        errors.additional_info_video = (this.state.data.additional_info_video === '');
        error = error || errors.additional_info_entertainment || errors.additional_info_photographer || errors.additional_info_video;
        this.setState({ errors });
        if (error === true) {
            return;
        }

        this.setState({ sending: true });

        axios({
            method: 'post',
            url: `${ process.env.PUBLIC_URL }/code/form-checklist.php`,
            data: {
                name: this.state.data.name.substring(0, 50),
                date_of_event: formatDate(this.state.data.date_of_event),
                bride_name: this.state.data.bride_name.substring(0, 50),
                groom_name: this.state.data.groom_name.substring(0, 50),
                event_planner_name: this.state.data.event_planner_name.substring(0, 50),
                event_planner_phone: this.state.data.event_planner_phone.substring(0, 50),
                bride_address: this.state.data.bride_address.substring(0, 100),
                bride_phone: this.state.data.bride_phone.substring(0, 50),
                bride_time: this.state.data.bride_time.substring(0, 50),
                bride_emergency_phone: this.state.data.bride_emergency_phone.substring(0, 50),
                groom_address: this.state.data.groom_address.substring(0, 100),
                groom_phone: this.state.data.groom_phone.substring(0, 50),
                groom_time: this.state.data.groom_time.substring(0, 50),
                groom_emergency_phone: this.state.data.groom_emergency_phone.substring(0, 50),
                church_name: this.state.data.church_name.substring(0, 50),
                church_address: this.state.data.church_address.substring(0, 100),
                church_contact: this.state.data.church_contact.substring(0, 50),
                church_time: this.state.data.church_time.substring(0, 50),
                venue_name: this.state.data.venue_name.substring(0, 50),
                venue_address: this.state.data.venue_address.substring(0, 100),
                banquet_manager: this.state.data.banquet_manager.substring(0, 50),
                start_time: this.state.data.start_time.substring(0, 50),
                end_time: this.state.data.end_time.substring(0, 50),
                additional_info_entertainment: this.state.data.additional_info_entertainment.substring(0, 100),
                additional_info_photographer: this.state.data.additional_info_photographer.substring(0, 100),
                additional_info_video: this.state.data.additional_info_video.substring(0, 100),
                additional_info_boutique: this.state.data.additional_info_boutique.substring(0, 100),
                additional_info_dress: this.state.data.additional_info_dress.substring(0, 100),
                additional_info_tux: this.state.data.additional_info_tux.substring(0, 100),
                additional_info_mekup: this.state.data.additional_info_mekup.substring(0, 100),
                additional_info_hair: this.state.data.additional_info_hair.substring(0, 100),
                additional_info_limo: this.state.data.additional_info_limo.substring(0, 100),
            },
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then((response) => {
                if (response.data && response.data.success === true) {
                    this.setState({
                        step: 1,
                        data: {
                            ...emptyData,
                        },
                        errors: {
                            ...emptyErrors,
                        },
                        responseType: 'success',
                        sending: false
                    });
                    setTimeout(() => {
                        this.setState({ responseType: null });
                    }, 5000);
                } else {
                    this.setState({
                        responseType: 'error',
                        sending: false
                    });
                }
            })
            .catch(() => {
                this.setState({ responseType: 'error', sending: false });
            });
    }

    render() {
        const { router } = this.props;
        const { step, data, errors } = this.state;

        return (
            <Fragment>
                <Intro
                    title="Worry and stress<br/> won’t go with your dress<br/> on your wedding day!"
                    description="We’ll give you one less thing to worry about. Leave us all of the details here, and we promise that everything will go smoothly, and that your flowers are picture perfect."
                />
                <div className="container-checklist">
                    <div className="page-content">
                        <div className="page-checklist">
                            <img
                                src={ require('assets/checklist/image.jpg') }
                                srcSet={ `${ require('assets/checklist/image.jpg') } 1x, ${ require('assets/checklist/image@2x.jpg') } 2x` }
                                alt="Final Consultation List"
                                className="checklist-image"
                            />
                            {
                                this.state.responseType === 'success' && (
                                    <div className="checklist-form-container">
                                        <div className="checklist-form">
                                            <div className="checklist-form-success">
                                                <p>Your message has been sent successfully. Thank you for writing to us. We are thrilled to be part of your special day and helping you turn your ceremony into unforgettable moments.</p>
                                                <p>Dahlia Team</p>
                                                <p className="links">
                                                    <Link router={ router } href={ router.url.home() }>
                                                        Return to homepage
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.state.responseType !== 'success' && (
                                    <form
                                        methpd="post"
                                        action=""
                                        className="checklist-form-container"
                                        onSubmit={ this.handleSubmit }
                                        noValidate
                                    >
                                        <div className="checklist-form">
                                            <FormNav step={ step }/>
                                            <FormProgress step={ step }/>
                                            <div className="checklist-form-steps">
                                                <FormStep1 active={ step === 1 } data={ data } errors={ errors } inputChange={ this.inputChange }/>
                                                <FormStep2 active={ step === 2 } data={ data } errors={ errors } inputChange={ this.inputChange }/>
                                                <FormStep3 active={ step === 3 } data={ data } errors={ errors } inputChange={ this.inputChange }/>
                                                <FormStep4 active={ step === 4 } data={ data } errors={ errors } inputChange={ this.inputChange }/>
                                                <FormStep5 active={ step === 5 } data={ data } errors={ errors } inputChange={ this.inputChange }/>
                                                <FormStep6 active={ step === 6 } data={ data } errors={ errors } inputChange={ this.inputChange }/>
                                            </div>
                                        </div>
                                        {
                                            (this.state.responseType === 'error' || this.errorAny()) && (
                                                <div className="checklist-form-message error">
                                                    <span>One or more fields have an error. Please check and try again.</span>
                                                </div>
                                            )
                                        }
                                        <FormButtons
                                            step={ step }
                                            prevStep={ this.prevStep }
                                            nextStep={ this.nextStep }
                                            formSubmit={ this.formSubmit }
                                            disabled={ this.state.sending || this.state.responseType === 'success' }
                                        />
                                    </form>
                                )
                            }
                        </div>
                    </div>
                </div>
                <FooterQuote type="checklist"/>
            </Fragment>
        );
    }
}

Checklist.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Checklist;
