import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from 'components/elements/Input';

const FormStep3 = ({ active, data, errors, inputChange }) => (
    <div className={ cx('checklist-form-step', { active }) }>
        <Input
            scope="checklist"
            field="groom_address"
            label="Address (Street, City, State and Zip Code) *"
            value={ data.groom_address }
            error={ errors.groom_address }
            inputChange={ inputChange('groom_address') }
        />
        <Input
            scope="checklist"
            field="groom_phone"
            label="Phone number *"
            value={ data.groom_phone }
            error={ errors.groom_phone }
            inputChange={ inputChange('groom_phone') }
        />
        <Input
            type="time"
            scope="checklist"
            field="groom_time"
            label="Time *"
            value={ data.groom_time }
            error={ errors.groom_time }
            inputChange={ inputChange('groom_time', 'time') }
        />
        <Input
            scope="checklist"
            field="groom_emergency_phone"
            label="Emergency phone number *"
            value={ data.groom_emergency_phone }
            error={ errors.groom_emergency_phone }
            inputChange={ inputChange('groom_emergency_phone') }
        />
        <div className="checklist-form-step-required">* Required Fields</div>
    </div>
);

FormStep3.propTypes = {
    active: PropTypes.bool.isRequired,
    data: PropTypes.shape().isRequired,
    inputChange: PropTypes.func.isRequired
};

export default FormStep3;
