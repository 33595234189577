import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from 'components/elements/Input';

const FormStep4 = ({ active, data, errors, inputChange }) => (
    <div className={ cx('checklist-form-step', { active }) }>
        <Input
            scope="checklist"
            field="church_name"
            label="Church name / on-site *"
            value={ data.church_name }
            error={ errors.church_name }
            inputChange={ inputChange('church_name') }
        />
        <Input
            scope="checklist"
            field="church_address"
            label="Address (Street, City, State and Zip Code) *"
            value={ data.church_address }
            error={ errors.church_address }
            inputChange={ inputChange('church_address') }
        />
        <Input
            scope="checklist"
            field="church_contact"
            label="Contact Name *"
            value={ data.church_contact }
            error={ errors.church_contact }
            inputChange={ inputChange('church_contact') }
        />
        <Input
            type="time"
            scope="checklist"
            field="church_time"
            label="Ceremony time *"
            value={ data.church_time }
            error={ errors.church_time }
            inputChange={ inputChange('church_time', 'time') }
        />
        <div className="checklist-form-step-required">* Required Fields</div>
    </div>
);

FormStep4.propTypes = {
    active: PropTypes.bool.isRequired,
    data: PropTypes.shape().isRequired,
    inputChange: PropTypes.func.isRequired
};

export default FormStep4;
