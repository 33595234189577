import React from 'react';
import PropTypes from 'prop-types';

const Link = ({
    router, href, className, children, callback, dangerousHTML, style, shouldScroll, overrideCallback
}) => {
    const linkClick = (e) => {
        e.preventDefault();
        if (typeof overrideCallback === 'function') {
            overrideCallback();
            return;
        }

        router.push(href, shouldScroll);

        if (typeof callback === 'function') {
            callback();
        }
    };
    return (
        dangerousHTML ? (
            <a
              href={ href }
              className={ className }
              style={ style }
              onClick={ linkClick }
              dangerouslySetInnerHTML={ { __html: dangerousHTML } }
            />
        ) : (
            <a
              href={ href }
              className={ className }
              style={ style }
              onClick={ linkClick }
            >
                { children }
            </a>
        )
    );
};

Link.defaultProps = {
    className: null,
    children: null,
    callback: null,
    dangerousHTML: null,
    style: null,
    shouldScroll: true,
    overrideCallback: null
};

Link.propTypes = {
    router: PropTypes.shape().isRequired,
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    callback: PropTypes.func,
    dangerousHTML: PropTypes.string,
    style: PropTypes.shape(),
    shouldScroll: PropTypes.bool,
    overrideCallback: PropTypes.func
};

export default Link;
