import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class SliderGallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageIndex: 0
        };

        this.imagesMaxIndex = this.props.images.length - 1;

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    prev() {
        let { imageIndex } = this.state;
        if (imageIndex <= 0) {
            return;
        }

        imageIndex--;
        this.setState({ imageIndex });
    }

    next() {
        let { imageIndex } = this.state;
        if (imageIndex >= this.imagesMaxIndex) {
            return;
        }

        imageIndex++;
        this.setState({ imageIndex });
    }

    render() {
        const { imageIndex } = this.state;
        const percent = imageIndex * (100 / this.props.images.length)
        const cssStyle = {
            transform: `translate(-${ percent }%, 0)`
        };

        return (
            <div className={ cx('slider-gallery', this.props.className) }>
                <div className="slider-gallery-track">
                    <div className="slider-gallery-slides" style={ cssStyle }>
                        {
                            this.props.images.map(({ src, src2x }, index) => (
                                <div className="slider-gallery-slide" key={ index }>
                                    <img
                                        src={ src }
                                        srcSet={ `${ src } 1x, ${ src2x } 2x` }
                                        alt=""
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="slider-gallery-nav">
                    <div onClick={ this.prev } className={ cx('slider-gallery-prev', { disabled: imageIndex <= 0 }) }/>
                    <div onClick={ this.next } className={ cx('slider-gallery-next', { disabled: imageIndex >= this.imagesMaxIndex }) }/>
                </div>
            </div>
        );
    }
}

SliderGallery.defaultProps = {
    className: '',
};


SliderGallery.propTypes = {
    images: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
};

export default SliderGallery;
