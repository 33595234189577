import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from 'components/elements/Input';

const FormStep5 = ({ active, data, errors, inputChange }) => (
    <div className={ cx('checklist-form-step', { active }) }>
        <Input
            scope="checklist"
            field="venue_name"
            label="Venue *"
            value={ data.venue_name }
            error={ errors.venue_name }
            inputChange={ inputChange('venue_name') }
        />
        <Input
            scope="checklist"
            field="venue_address"
            label="Address (Street, City, State and Zip Code) *"
            value={ data.venue_address }
            error={ errors.venue_address }
            inputChange={ inputChange('venue_address') }
        />
        <Input
            scope="checklist"
            field="banquet_manager"
            label="Banquet manager *"
            value={ data.banquet_manager }
            error={ errors.banquet_manager }
            inputChange={ inputChange('banquet_manager') }
        />
        <Input
            type="time"
            scope="checklist"
            field="start_time"
            label="Start time *"
            value={ data.start_time }
            error={ errors.start_time }
            inputChange={ inputChange('start_time', 'time') }
        />
        <Input
            type="time"
            scope="checklist"
            field="end_time"
            label="End time *"
            value={ data.end_time }
            error={ errors.end_time }
            inputChange={ inputChange('end_time', 'time') }
        />
        <div className="checklist-form-step-required">* Required Fields</div>
    </div>
);

FormStep5.propTypes = {
    active: PropTypes.bool.isRequired,
    data: PropTypes.shape().isRequired,
    inputChange: PropTypes.func.isRequired
};

export default FormStep5;
