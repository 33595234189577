import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SliderGallery from 'components/elements/SliderGallery';

class Testimonials extends Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();

        this.state = {
            percent: 0
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const windowHeight = window.innerHeight;

        const containerBox = this.containerRef.current.getBoundingClientRect();
        const containerStyle = getComputedStyle(this.containerRef.current);
        const containerHeight = this.containerRef.current.offsetHeight + parseInt(containerStyle.marginTop) + parseInt(containerStyle.marginBottom);
        const containerTop = containerBox.top;

        const startTop = windowHeight + parseInt(containerStyle.marginTop);
        const endTop = 0;

        let percent = -0.5;
        if (containerTop <= startTop && containerTop >= endTop) {
            percent = 1 - (containerTop - endTop) / (containerHeight - parseInt(containerStyle.marginTop)) - 0.5;
        } else if (containerTop < endTop) {
            percent = 0.5;
        }

        this.setState({ percent });
    }

    render() {
        const { router: { breakpoint } } = this.props;
        let baseMove = 200;
        if (breakpoint === 'tablet-hor') {
            baseMove = 150;
        } else if (breakpoint === 'tablet-ver') {
            baseMove = 0;
        } else if (breakpoint === 'phone') {
            baseMove = 0;
        }

        const quoteMainStyle = {
            transform: `translate(0, ${ baseMove * this.state.percent }px, )`
        };
        const quoteSideStyle = {
            transform: `translate(0, ${ baseMove * this.state.percent }px)`
        };
        const imageStyle = {
            transform: `translate(0, ${ (-1) * baseMove * this.state.percent }px)`
        }

        return (
            <div className="container-home-testimonials" ref={ this.containerRef }>
                <div className="page-content">
                    <div className="page-home-testimonials">
                        <blockquote className="home-testimonials-quote home-testimonials-quote-main" style={ quoteMainStyle }>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 32"><path d="M31.66,32a8.61,8.61,0,0,1-6.57-2.68,10.31,10.31,0,0,1-2.41-7.08,18.39,18.39,0,0,1,1.18-6.56A19.76,19.76,0,0,1,27.43,9.8c3.23-3.72,8-6.93,14.65-9.8L43,1.56C33.45,6.14,28.57,13.49,28,18.74l.52.25a5.8,5.8,0,0,1,3.56-1.16,6.93,6.93,0,0,1,5,1.94,6.55,6.55,0,0,1,1.9,4.69,7.67,7.67,0,0,1-2,5.28A7,7,0,0,1,31.66,32ZM9,32a8.57,8.57,0,0,1-6.56-2.68A10.33,10.33,0,0,1,0,22.24a18.6,18.6,0,0,1,1.17-6.56A19.58,19.58,0,0,1,4.75,9.8C8,6.08,12.76,2.87,19.38,0l.93,1.56c-9.54,4.57-14.43,11.92-15,17.18L5.8,19a5.8,5.8,0,0,1,3.57-1.16,7,7,0,0,1,5,1.94,6.59,6.59,0,0,1,1.9,4.69,7.73,7.73,0,0,1-2.05,5.28A7,7,0,0,1,9,32Z"/></svg>
                            <p>Words can not express how wonderful Dahlia Floral & Events are. The moment I went to Dahlia’s, they knew my vision and created my dream wedding. From the centerpieces, bridesmaids bouquets, the boutonnieres, and backdrop behind our sweetheart table it was everything I’ve ever dreamt of and more. My guest still keep talking about how beautiful everything looked! I would definitely recommend Dahlia Floral & Events for your big day!!</p>
                        </blockquote>
                        <blockquote className="home-testimonials-quote home-testimonials-quote-side" style={ quoteSideStyle }>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 32"><path d="M31.66,32a8.61,8.61,0,0,1-6.57-2.68,10.31,10.31,0,0,1-2.41-7.08,18.39,18.39,0,0,1,1.18-6.56A19.76,19.76,0,0,1,27.43,9.8c3.23-3.72,8-6.93,14.65-9.8L43,1.56C33.45,6.14,28.57,13.49,28,18.74l.52.25a5.8,5.8,0,0,1,3.56-1.16,6.93,6.93,0,0,1,5,1.94,6.55,6.55,0,0,1,1.9,4.69,7.67,7.67,0,0,1-2,5.28A7,7,0,0,1,31.66,32ZM9,32a8.57,8.57,0,0,1-6.56-2.68A10.33,10.33,0,0,1,0,22.24a18.6,18.6,0,0,1,1.17-6.56A19.58,19.58,0,0,1,4.75,9.8C8,6.08,12.76,2.87,19.38,0l.93,1.56c-9.54,4.57-14.43,11.92-15,17.18L5.8,19a5.8,5.8,0,0,1,3.57-1.16,7,7,0,0,1,5,1.94,6.59,6.59,0,0,1,1.9,4.69,7.73,7.73,0,0,1-2.05,5.28A7,7,0,0,1,9,32Z"/></svg>
                            <p>From the moment I walked through the doors at Dahlia I was pretty sure I had made a good decision. I was so impressed with their work and with their professionalism I hired them on the spot! Best decision ever!!</p>
                        </blockquote>
                        <SliderGallery images={ window.globalHomeGallery } className="slider-gallery-home-testimonials"/>
                        <div className="home-testimonials-image" style={ imageStyle }>
                            <img
                                src={ require('assets/home/testimonials-image.jpg') }
                                srcSet={ `${ require('assets/home/testimonials-image.jpg') } 1x, ${ require('assets/home/testimonials-image@2x.jpg') } 2x` }
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Testimonials.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Testimonials;
