import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Link from 'components/helpers/Link';
import Input from 'components/elements/Input';
import Intro from 'components/elements/Intro';
import FooterQuote from 'components/elements/FooterQuote';

const formatDate = (d) => {
    if (d instanceof Date && !isNaN(d.valueOf())) {
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        return `${ month }/${ day }/${ year }`;
    }
    return '';
}

const budgets = [{
    value: 'Please note, our prices start at $10,000 & up',
    title: 'Please note, our prices start at $10,000 & up',
}, {
    value: '$10,000-$12,000',
    title: '$10,000-$12,000',
}, {
    value: '$12,000-$16,000',
    title: '$12,000-$16,000',
}, {
    value: '$16,000-$20,000',
    title: '$16,000-$20,000',
}, {
    value: '$20,000-$30,000',
    title: '$20,000-$30,000',
}, {
    value: '$30,000-$40,000',
    title: '$30,000-$40,000',
}, {
    value: '$40,000 +',
    title: '$40,000 +',
}];

const sources = [{
    value: 'Search engine',
    title: 'Search engine (e.g. Google, Bing, Yahoo)',
}, {
    value: 'Social media',
    title: 'Social media (e.g. Facebook, Instagram, Twitter)',
}, {
    value: 'Referral',
    title: 'Referral from a friend or family member',
}, {
    value: 'Advertisement',
    title: 'Advertisement (e.g. Google Ads, Facebook Ads, Instagram Ads)',
}, {
    value: 'Wedding website',
    title: 'Wedding website (e.g. The Knot, WeddingWire)',
}, {
    value: 'Other website or blog',
    title: 'Other website or blog',
}, {
    value: 'Print advertisement',
    title: 'Print advertisement (e.g. magazine, newspaper)',
}, {
    value: 'Other',
    title: 'Other',
}];

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            phone: '',
            email: '',
            date_of_event: '',
            type_of_event: '',
            location: '',
            guests: '',
            budget: '',
            source: '',
            source_other: '',
            error: {
                name: false,
                phone: false,
                email: false,
                date_of_event: false,
                type_of_event: false,
                location: false,
                guests: false,
                budget: false,
                source: false,
                source_other: false,
            },
            responseType: null,
            sending: false,
        };

        this.inputChange = this.inputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.errorAny = this.errorAny.bind(this);
    }

    errorAny() {
        return Object.keys(this.state.error).reduce((acc, key) => acc || this.state.error[key], false);
    }

    inputChange(field, type) {
        return (e) => {
            switch (type) {
                case 'date': {
                    this.setState({
                        [field]: e
                    });
                    break;
                }
                default: {
                    this.setState({
                        [field]: e.target.value
                    });
                    break;
                }
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const {
            name, phone, email, date_of_event, type_of_event, location, guests, budget, source, source_other
        } = this.state;

        const error = {
            name: false,
            phone: false,
            email: false,
            date_of_event: false,
            type_of_event: false,
            location: false,
            guests: false,
            budget: false,
            source: false,
            source_other: false,
        };

        error.name = (name === '');
        const emailRegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/; // eslint-disable-line max-len
        error.email = !emailRegExp.test(email);
        error.phone = (phone === '');
        error.date_of_event = (date_of_event === '');
        error.type_of_event = (type_of_event === '');
        error.location = (location === '');
        error.guests = (guests === '');
        error.budget = (budget === '');
        error.source = (source === '');

        const errorAny = Object.keys(error).reduce((acc, key) => error[key] || acc, false);
        if (errorAny === false) {
            this.setState({ sending: true });

            axios({
                method: 'post',
                url: `${ process.env.PUBLIC_URL }/code/form-contact.php`,
                data: {
                    name: name.substring(0, 50),
                    phone: phone.substring(0, 20),
                    email: email.substring(0, 100),
                    date_of_event: formatDate(date_of_event),
                    type_of_event: type_of_event.substring(0, 100),
                    location: location.substring(0, 100),
                    guests: guests.substring(0, 100),
                    budget: budget.substring(0, 100),
                    source: source.substring(0, 100),
                    source_other: source_other.substring(0, 255),
                },
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
                .then((response) => {
                    if (response.data && response.data.success === true) {
                        this.setState({
                            name: '',
                            phone: '',
                            email: '',
                            date_of_event: '',
                            type_of_event: '',
                            location: '',
                            guests: '',
                            budget: '',
                            source: '',
                            source_other: '',
                            error: {
                                name: false,
                                phone: false,
                                email: false,
                                date_of_event: false,
                                type_of_event: false,
                                location: false,
                                guests: false,
                                budget: false,
                                source: false,
                                source_other: false,
                            },
                            responseType: 'success',
                            sending: false
                        });
                        setTimeout(() => {
                            this.setState({ responseType: null });
                        }, 5000);
                    } else {
                        this.setState({
                            error: {
                                name: !!response.data.message.name,
                                phone: !!response.data.message.phone,
                                email: !!response.data.message.email,
                                date_of_event: !!response.data.message.date_of_event,
                                type_of_event: !!response.data.message.type_of_event,
                                location: !!response.data.message.location,
                                guests: !!response.data.message.guests,
                                budget: !!response.data.message.budget,
                                source: !!response.data.message.source,
                            },
                            responseType: 'error',
                            sending: false
                        });
                    }
                })
                .catch(() => {
                    this.setState({ responseType: 'error', sending: false });
                });
        } else {
            this.setState({ error, responseType: 'error' });
        }
    }

    render() {
        const { router } = this.props;

        return (
            <Fragment>
                <Intro
                    title="Who, Us? (Blush)<br/> We’d Be Honored to Be<br/> a Part of Your Day"
                    description="Reach out to us today to discover how we can lend our creativity and expertise to create extraordinary custom floral designs for your special day."
                />
                <div className="page-content">
                    <div className="page-contact">
                        <div className="contact-details">
                            <div className="contact-details-section">
                                <p>
                                    5 Lanza Avenue<br/>
                                    Garfield 07026, New Jersey
                                </p>
                                <p>
                                    Tue – Sat / 9am – 5pm<br/>
                                    Tue – Thur / late consultation night<br/>
                                    Sun – Mon / closed
                                </p>
                            </div>
                            <div className="contact-details-section contact-details-phone-email">
                                <p>
                                    Telephone<br/>
                                    <a href="tel:+19737720219">+1  973. 772. 0219</a>
                                </p>
                                <p>
                                    Email<br/>
                                    <a href="mailto:info@dahliaflorals.com">info@dahliaflorals.com</a>
                                </p>
                            </div>
                        </div>
                        {
                            this.state.responseType === 'success' && (
                                <div className="contact-form">
                                    <div className="contact-form-success">
                                        <p>Your message has been sent successfully. Thank you for writing to us. We are thrilled to be part of your special day and helping you turn your ceremony into unforgettable moments.</p>
                                        <p>Dahlia Team</p>
                                        <p className="links">
                                            <Link router={ router } href={ router.url.home() }>
                                                Return to homepage
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.responseType !== 'success' && (
                                <form
                                    methpd="post"
                                    action=""
                                    className="contact-form"
                                    onSubmit={ this.handleSubmit }
                                    noValidate
                                >
                                    <Input
                                        scope="contact"
                                        field="name"
                                        label="Name *"
                                        value={ this.state.name }
                                        error={ this.state.error.name }
                                        inputChange={ this.inputChange('name') }
                                    />
                                    <Input
                                        type="tel"
                                        scope="contact"
                                        field="phone"
                                        label="Phone number"
                                        value={ this.state.phone }
                                        error={ this.state.error.phone }
                                        inputChange={ this.inputChange('phone') }
                                    />
                                    <Input
                                        type="email"
                                        scope="contact"
                                        field="email"
                                        label="Email Address *"
                                        value={ this.state.email }
                                        error={ this.state.error.email }
                                        inputChange={ this.inputChange('email') }
                                    />
                                    <Input
                                        type="date"
                                        scope="contact"
                                        field="date_of_event"
                                        label="Date of event"
                                        value={ this.state.date_of_event }
                                        error={ this.state.error.date_of_event }
                                        inputChange={ this.inputChange('date_of_event', 'date') }
                                    />
                                    <Input
                                        scope="contact"
                                        field="type_of_event"
                                        label="Type of event"
                                        value={ this.state.type_of_event }
                                        error={ this.state.error.type_of_event }
                                        inputChange={ this.inputChange('type_of_event') }
                                    />
                                    <Input
                                        scope="contact"
                                        field="location"
                                        label="Where is the reception to be held?"
                                        value={ this.state.location }
                                        error={ this.state.error.location }
                                        inputChange={ this.inputChange('location') }
                                    />
                                    <Input
                                        scope="contact"
                                        field="guests"
                                        label="How many guests do you expect?"
                                        value={ this.state.guests }
                                        error={ this.state.error.guests }
                                        inputChange={ this.inputChange('guests') }
                                    />
                                    <Input
                                        type="select"
                                        scope="contact"
                                        field="source"
                                        label="How did you hear about us?"
                                        value={ this.state.source }
                                        error={ this.state.error.source }
                                        selectValues={ sources }
                                        inputChange={ this.inputChange('source') }
                                    />
                                    {this.state.source === 'Other' && <Input
                                        scope="contact"
                                        field="source_other"
                                        label="Other"
                                        value={ this.state.source_other }
                                        error={ this.state.error.source_other }
                                        inputChange={ this.inputChange('source_other') }
                                    />}
                                    <Input
                                        type="select"
                                        scope="contact"
                                        field="budget"
                                        label="What is your budget?"
                                        value={ this.state.budget }
                                        error={ this.state.error.budget }
                                        selectValues={ budgets }
                                        inputChange={ this.inputChange('budget') }
                                    />
                                    <div className="contact-form-required">* Required Fields</div>
                                    <div className="contact-button-box">
                                        <button
                                            type="submit"
                                            className="button button-80"
                                            disabled={ this.state.sending || this.state.responseType === 'success' }
                                        >
                                            <span className="button-inside">Submit</span>
                                        </button>
                                    </div>
                                    {
                                        (this.state.responseType === 'error' || this.errorAny()) && (
                                            <div className="contact-form-message error">
                                                <span>One or more fields have an error. Please check and try again.</span>
                                            </div>
                                        )
                                    }
                                </form>
                            )
                        }
                        <div className="contact-image">
                            <img
                                src={ require('assets/contact/image.jpg') }
                                srcSet={ `${ require('assets/contact/image.jpg') } 1x, ${ require('assets/contact/image@2x.jpg') } 2x` }
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <FooterQuote type="contact"/>
            </Fragment>
        );
    }
}

Contact.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Contact;
