import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';

import 'react-day-picker/lib/style.css';
// import 'react-times/css/material/default.css';
import './styles/index.scss';

import Router from './components/Router';

if (typeof window.globalGalleries === 'undefined') { window.globalGalleries = []; }
if (typeof window.globalMenuImages === 'undefined') { window.globalMenuImages = []; }
if (typeof window.globalFooterQuotes === 'undefined') { window.globalFooterQuotes = []; }
if (typeof window.globalAboutTeamGallery === 'undefined') { window.globalAboutTeamGallery = []; }
if (typeof window.globalAboutSliderGallery === 'undefined') { window.globalAboutSliderGallery = []; }
if (typeof window.globalHomeSlidesDesktop === 'undefined') { window.globalHomeSlidesDesktop = []; }
if (typeof window.globalHomeSlidesMobile === 'undefined') { window.globalHomeSlidesMobile = []; }
if (typeof window.globalHomeGallery === 'undefined') { window.globalHomeGallery = []; }

const history = createBrowserHistory();
const rootElement = document.getElementById('root');

ReactDOM.render(<Router history={ history }/>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
