import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/helpers/Link';

class Events extends Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.colorRef = React.createRef();

        this.state = {
            open: false,
            colorPercent: 0
        };

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
        document.addEventListener('resize', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        document.removeEventListener('resize', this.onScroll);
    }

    onScroll() {
        const windowHeight = window.innerHeight;

        if (!this.state.open) {
            const elemTop = this.containerRef.current.getBoundingClientRect().top;

            if (elemTop < windowHeight / 2) {
                this.setState({ open: true });
            }
        }

        const colorBox = this.colorRef.current.getBoundingClientRect();
        const colorHeight = colorBox.height;
        const colorTop = colorBox.top;

        const colorStartTop = windowHeight - colorHeight * 0.5;
        const colorEndTop = windowHeight - colorHeight * 1.5;

        let colorPercent = 1;
        if (colorTop <= colorStartTop && colorTop >= colorEndTop) {
            colorPercent = (colorTop - colorEndTop) / colorHeight;
        } else if (colorTop < colorEndTop) {
            colorPercent = 0;
        }

        this.setState({ colorPercent });
    }

    render() {
        const { router } = this.props;
        const { breakpoint } = router;
        const title = 'A Delicate Touch of Dahlia'.split(' ');
        const excerpt = 'We dress all weddings with heart-fluttering decor that sets the perfect scene for your special day.'.split(' ');

        let delay = 0;
        const calcDelayStyle = () => {
            const style = {
                transitionDelay: `${ delay }s`
            };
            delay += 0.1;
            return style;
        }

        const colorStyle = {
            width: `calc(1480px - ${ 720 * this.state.colorPercent }px)`
        };
        if (breakpoint === 'laptop') {
            colorStyle.width = `calc(1297px - ${ 630 * this.state.colorPercent }px)`;
        } else if (breakpoint === 'tablet-hor') {
            colorStyle.width = `calc(926px - ${ 450 * this.state.colorPercent }px)`;
        } else if (breakpoint === 'tablet-ver') {
            colorStyle.width = `calc(1077px - ${ 524 * this.state.colorPercent }px)`;
        } else if (breakpoint === 'phone') {
            colorStyle.width = `calc(657px - ${ 320 * this.state.colorPercent }px)`;
        }

        return (
            <div className="container-home-events" ref={ this.containerRef }>
                <div className="page-content">
                    <div className="page-home-events">
                        <div className={ cx('home-events-content', { open: this.state.open }) }>
                            <h3>
                                {
                                    title.map((word, index) => (
                                        <span className="reveal" style={ calcDelayStyle() } key={ index }>{ word } </span>
                                    ))
                                }
                            </h3>
                            <p className="excerpt">
                                {
                                    excerpt.map((word, index) => (
                                        <span className="reveal" style={ calcDelayStyle() } key={ index }>{ word } </span>
                                    ))
                                }
                            </p>
                            <p className="desc reveal2" style={ calcDelayStyle() }>
                                From fresh floral centerpieces, to stunning candle-lit tablescapes, to romantic bridal bouquets, we’ll add our creative Dahlia touch to your wedding day to make you feel like royalty.
                            </p>
                            <Link router={ router } href={ router.url.gallery() } className="link reveal2" style={ calcDelayStyle() }>
                                View gallery
                            </Link>
                        </div>
                        <div className="home-events-image">
                            <img
                                src={ require('assets/home/events-bw.jpg') }
                                srcSet={ `${ require('assets/home/events-bw.jpg') } 1x, ${ require('assets/home/events-bw@2x.jpg') } 2x` }
                                alt=""
                                className="image-bw"
                            />
                        </div>
                        <div className="home-events-color" style={ colorStyle } ref={ this.colorRef }>
                            <img
                                src={ require('assets/home/events-color.jpg') }
                                srcSet={ `${ require('assets/home/events-color.jpg') } 1x, ${ require('assets/home/events-color@2x.jpg') } 2x` }
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Events.propTypes = {
    router: PropTypes.shape().isRequired
};

export default Events;
